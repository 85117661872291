import { NotificationManager } from '@mm/utils/src/react-notifications'
import { ColumnReporting } from '../types'

export const getColumnsViewConfig = (
  columns: Array<ColumnReporting> | null
) => {
  if (!columns) return { order: {}, activeColumns: [] }
  return columns.reduce(
    (
      acc: { order: { [col: string]: number }; activeColumns: string[] },
      curr: ColumnReporting
    ) => {
      if (curr.active) acc.activeColumns.push(curr.data.value)
      acc.order[curr.data.value] = curr.order
      return acc
    },
    { order: {}, activeColumns: [] }
  )
}

export const handleError = (e: any) => {
  const message = e.response ? e.response.data.message || 'Error' : e.message
  NotificationManager.warning(message, 'Error', 3000, null, null, '')
}
