import { MultiSelectOption } from '@mm/ui/src/components/SmartTable/Constants'

export interface RowData {
  [key: string]: { current: string } | string | null
}

export interface ColData {
  id: number
  hidden: boolean
  active: boolean
  label: string
  order: number
  width: number
  tableId: string
  type: string
  data: Data
  stored?: { [key: string]: string }
}

export type NumberTypeOptions =
  | 'number'
  | 'currency'
  | 'percentage'
  | 'date'
  | 'default'

export type DateTypeOptions =
  | 'manual'
  | 'isToday'
  | 'firstDayOfMonth'
  | 'lastDayOfMonth'
  | 'firstDayOverviewDate'
  | 'lastDayOverviewDate'

export type Data = ColumnDataBase &
  CloseCustomFieldConfig &
  HubspotCustomFieldConfig

export type ColumnDataBase = {
  value: string
  type: string
  options?: Array<string>
  formula?: string
  default?: string
  stored?: { [rowId: string]: string }
  numberType: NumberTypeOptions
  dateType: DateTypeOptions
  decimals?: number
  filterEnabled?: boolean
  compare?: {
    enabled: boolean
    period: 'Previous period' | 'Customize'
    from?: string
    to?: string
  }
  source: string
  fixedOrder?: number
  alias?: string
  crm?: string
}

export interface Dashboard {
  id: number
  name: string
  sort?: any
  dateFilterTo?: any
  dateFilterFrom?: any
  dateFilterRange?: any
  layout: Layout[]
  dateFilterLastDays?: any
  dateFilterUntilToday?: any
  createdAt: string
  updatedAt: string
  deletedAt?: any
  userId: number
  stats: Stat[]
  filters: any[]
}

interface Stat {
  id: string
  name?: string
  grouped?: string
  range: string
  from?: string
  to?: string
  type: string
  chartType?: string
  barStacking?: any
  lineStacking?: any
  lastDays?: number
  untilToday?: boolean
  createdAt: string
  updatedAt: string
  deletedAt?: any
  dashboard_widgets: Dashboardwidgets
  metrics: Metric[]
}

interface Metric {
  id: number
  name: string
  source?: string
  type: string
  query?: string
  queryValue?: any
  float?: number
  percentage?: boolean
  opportunityQuery?: any
  date?: any
  sort?: any
  closeLeadStatusNew: string
  closeLeadStatusOld: string
  closeOpportunityStatusNew: string
  closeOpportunityStatusOld: string
  closeStatusChangeStatusNew: string
  closeStatusChangeStatusOld: string
  filter?: any
  serviceAccountId?: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  userId: number
  stats_metrics: Statsmetrics
}

interface Statsmetrics {
  metricId: number
  statId: string
  createdAt: string
  updatedAt: string
  deletedAt?: any
}

interface Dashboardwidgets {
  id: string
  dashboard_id: number
  widget_id: string
  widget_type: string
  sort: number
  createdAt: string
  updatedAt: string
  deletedAt?: any
}

interface Layout {
  h: number
  i: string
  w: number
  x: number
  y: number
  type: string
  minH?: number
  minW?: number
}

export enum ColumnTypes {
  SINGLE_TEXT = 'single_text',
  LONG_TEXT = 'long_text',
  CHECKBOX = 'checkbox',
  SINGLE_SELECT = 'single_select',
  MULTIPLE_SELECT = 'multiple_select',
  URL = 'url',
  NUMBER = 'number',
  DATE = 'date',
  FORMULA = 'formula',
  METRIC = 'metric',
  RESULT_FORMULA = 'result_formula',
  SOURCE_RESULT = 'source_result',
  CRM = 'crm',
}

export type TableDate = {
  since: string
  until: string
  name?: string
  lastDays?: string
  untilToday?: boolean
  disabled?: boolean
}

export type CloseCustomFieldConfig = {
  metric?: string
  matchDate?: string
  smartView?: string
  assignedUser?: string[]
  statusLabel?: string[]
}
export type HubspotCustomFieldConfig = {
  metric?: string
  pipelineStage?: string
  matchResource?: string
  matchProperty?: string
  filterOperator?: string
  filterValue?: string
  filterType?: string
}

export type ColumnData = ColumnDataBase &
  CloseCustomFieldConfig &
  HubspotCustomFieldConfig

export type ColumnReporting = {
  id: number
  type: ColumnTypes
  value: string
  order: number
  hidden: boolean
  width: number
  tableId: string
  label: string
  data: ColumnData
  active: boolean
  createdAt: string
  updatedAt: string
}

export type RawDate = {
  dateFilterFrom: string
  dateFilterTo: string
  dateFilterRange?: string
  dateFilterLastDays?: string
  dateFilterUntilToday?: boolean
  disabled?: boolean
}

export type TableAdAccount = {
  source: string
  id: string
  value: string
  label: string
  metaData?: { [key: string]: string }
}

export type TablesConfig = {
  sort?: {
    column: string
    order: string
  }
}

export type TableReporting = {
  id: string
  name: string
  date: TableDate
  group_by: 'campaign' | 'client'
  sources: Array<string>
  adAccounts: Array<TableAdAccount>
  columns: Array<ColumnReporting>
  clients?: Array<TableReportingClient>
  additional_config?: TablesConfig
  conditional_format: any
  duplicatedTable?: string
  targetClients?: string
}

export type ViewOrder = {
  [column: string]: number
}

export type ViewFilterItem = {
  id: string
  type: string
  filter: string
  value?: number | number[] | string | string[]
  property: string
}

export type ViewFilterCollection = {
  [key in 'AND' | 'OR']: ViewFilterItem[]
}

export type View = {
  id: string
  name: string
  tableId: string
  order: ViewOrder
  activeColumns: Array<string>
  filters: ViewFilterCollection
}

export type TableReportingClientConfiguration = {
  id: string
  source: MultiSelectOption
  accounts: MultiSelectOption[]
  campaigns: MultiSelectOption[]
}

export type TableReportingClient = {
  id: number
  name: string
  configuration: TableReportingClientConfiguration[]
}

export type TableFilterItem = {
  id: string
  property: string | null
  type: string
  filter: string | null
  value: string | null
  operator: string
}

export interface TableReportingFilters {
  AND: Array<TableFilterItem>
  OR: Array<TableFilterItem>
}

export interface CustomFieldInput {
  id?: number | string
  label: string
  type: string
  data: ColumnData
  deleted?: boolean
  updated?: boolean
  localId?: number | string
  order?: number
  width?: number
  active?: boolean
  tableId?: string
  hidden?: boolean
  createdAt?: string
}

export interface ClientConfigurationType {
  id: string
  source: { id: string; value: string; name: string } | null
  accounts: MultiSelectOption[]
  campaign?: {
    filter?: string
    value?: string | string[]
  }
}

export interface ClientData {
  id?: string
  name: string
  configuration: ClientConfigurationType[]
  sort?: number
  tableId?: string
  clientType?: 'ecommerce' | 'leadGeneration'
}
