export type Option = {
  label: string
  id: string | number
  optionType: string
  icon: string
  uuid: string | number
  type?: string
  options?: Option[]
  source?: string
  account?: string
  disabled?: boolean
  disabledReason?: string
  lastOption?: Option | null
  option?: Option
}
export const CalculatedV1 = 'C'
export const CalculatedV2 = 'CalculatedV2'

export const isCalculatedMetric = (type: string) => {
  return type === CalculatedV1 || type === CalculatedV2
}

export const parseFormulaCalcV2 = (
  formula: string,
  metricsData: { [key: string]: Option }
) => {
  try {
    const parseFormula = Object.entries(metricsData).reduce(
      (acc, [key, value]) => {
        acc = acc.replace(key, (value as { label: string }).label)
        return acc
      },
      formula
    )

    return {
      parseFormula: formula,
      formula,
      metricsData,
    }
  } catch (e) {
    return {
      parseFormula: formula,
      formula: formula,
      metricsData: {},
    }
  }
}
