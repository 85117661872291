import { useEffect, useState } from 'react'
import { normalizeColumnSizing } from './utils'
import { ColumnResizeMode, SortingState, Updater } from '@tanstack/react-table'

// @ts-ignore
const isAllowSetTableSettings = import.meta.env.VITE_APP_IS_PUBLIC === 'false'

const useSharedTableConfig = (configurations, updateTableSettings) => {
  const [columnSizingInfo, setColumnSizingInfo] = useState({})
  const [columnResizeMode] = useState<ColumnResizeMode>('onChange')
  const [sorting, setSorting] = useState<SortingState>(
    configurations?.columnSorting || []
  )
  const [columnSizing, setColumnSizing] = useState(
    configurations?.columnSizes || {}
  )

  const handleSortingChange = (updater: Updater<SortingState>) => {
    setSorting(updater)
    const newSortingValue =
      updater instanceof Function ? updater(sorting) : updater
    const payload = {
      columnSorting: newSortingValue,
    }
    isAllowSetTableSettings && updateTableSettings(payload)
  }

  useEffect(() => {
    const { isResizingColumn } = columnSizingInfo
    if (isResizingColumn === false && Object.keys(columnSizing).length) {
      const sizingNormalized = normalizeColumnSizing(columnSizing)
      const payload = {
        columnSizes: sizingNormalized,
      }
      isAllowSetTableSettings && updateTableSettings(payload)
    }
  }, [columnSizingInfo, columnSizing, updateTableSettings])

  return {
    sorting,
    handleSortingChange,
    columnResizeMode,
    columnSizing,
    setColumnSizing,
    columnSizingInfo,
    setColumnSizingInfo,
  }
}

export { useSharedTableConfig }
