import styled from 'styled-components'

export const ImageContainer = styled.div`
  width: 100px;
  min-width: 100px;
  border-radius: 6px;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
`

export const Title = styled.span`
  width: 100%;
  height: 30px;
  padding: 3px 0 0;
  font-size: 12px;

  white-space: normal;
  line-height: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Description = styled.p`
  margin-bottom: 0;
  padding-top: 6px;
  color: #787b90;
  font-family: 'Hind Guntur', sans-serif;
  font-size: 11px;
  font-weight: 400;

  white-space: normal;
  line-height: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ImageTemplateContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-family: 'Hind Guntur', sans-serif;
  overflow: hidden;

  a {
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
    padding-top: 4px;
    font-weight: 500;
    font-size: 10px;

    &:hover {
      color: #717bea;
    }

    svg {
      margin: 0 3px;

      &:hover {
        fill: #717bea;
      }
    }
  }
`

export const ImageTemplateContainer = styled.div<{
  useVerticalImageRowContent: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${({ useVerticalImageRowContent }) => {
    return (
      useVerticalImageRowContent &&
      `
        flex-direction: column;
        text-align: center;
        ${ImageTemplateContent} > a {
         align-self: center;
        }
        ${Title} {
          display: -webkit-box;
          white-space: normal;
        }
      `
    )
  }}
`
