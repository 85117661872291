import styled from 'styled-components'
import { ActionMenuButton } from '../WidgetActionMenu/styles'

export const WidgetWrapperStyled = styled.section`
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  padding: 1rem;
  background: white;
  border: initial;
  border-radius: 0.75rem;
  box-shadow:
    0 1px 15px rgba(0, 0, 0, 0.06),
    0 1px 6px rgba(0, 0, 0, 0.06);
  overflow: hidden;

  &:hover {
    ${ActionMenuButton} {
      visibility: visible;
    }
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ErrorMessage = styled.div``

export const WidgetHeader = styled.header<{ isExportPdf: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 1rem;

  & > div {
    align-self: flex-start;
    margin-right: 0.5rem;
    ${({ isExportPdf }) => isExportPdf && `padding-top: 8px`}
  }
`

export const WidgetTitle = styled.h2`
  margin: 0 1rem;
  color: #1b215c;
  font-size: 1.5rem;
  font-weight: 600;
`

export const DateRangeSelected = styled.span`
  margin: 0 1rem;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
`
export const WarningIconTooltipContainer = styled.div`
  width: 25px;
  height: 25px;
  position: relative;
  top: -10px;
  margin-right: 1rem !important;
`
