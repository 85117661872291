import React from 'react'
import IntlMessage from '@mm/utils/src/helpers/IntlMessages'
import IconComponent, {
  IconNames,
  IconVariable,
} from '@mm/ui/src/components/IconComponent/IconComponent'
import {
  Description,
  ImageContainer,
  ImageTemplateContainer,
  ImageTemplateContent,
  Title,
} from './styles'
import { ImageRowContentProps } from './types'

const ImageRowContent = ({
  useVerticalImageRowContent,
  imageAdditionalData,
  cellValue = '',
  isExportPdf,
}: ImageRowContentProps) => {
  const { url, title, description, externalLink } =
    imageAdditionalData?.[cellValue] || {}
  const isImageURL = url || cellValue?.includes('https://')
  return (
    <ImageTemplateContainer
      useVerticalImageRowContent={isImageURL && useVerticalImageRowContent}
    >
      {isImageURL ? (
        <ImageContainer>
          <img
            src={url || cellValue}
            alt={title || ''}
            loading={isExportPdf ? 'eager' : 'lazy'}
          />
        </ImageContainer>
      ) : (
        <span>{cellValue}</span>
      )}
      {imageAdditionalData && (
        <ImageTemplateContent>
          {title && <Title title={title}>{title}</Title>}
          {description && (
            <Description title={description}>{description}</Description>
          )}
          {externalLink && (
            <a
              href={externalLink}
              title="Preview"
              target="_blank"
              rel="noreferrer"
            >
              <IntlMessage id="general.view" />
              <IconComponent
                icon={IconNames.NewTabIcon}
                variable={IconVariable.Black}
              />
            </a>
          )}
        </ImageTemplateContent>
      )}
    </ImageTemplateContainer>
  )
}

export { ImageRowContent }
