import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable, width = '15', height = '15' }) => (
  <svg width={width} height={height} viewBox="0 0 3 13" fill="none">
    <path
      d="M1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5C3 2.32843 2.32843 3 1.5 3Z"
      fill={IconsColors[variable]}
    />
    <path
      d="M1.5 8C0.671573 8 0 7.32843 0 6.5C0 5.67157 0.671573 5 1.5 5C2.32843 5 3 5.67157 3 6.5C3 7.32843 2.32843 8 1.5 8Z"
      fill={IconsColors[variable]}
    />
    <path
      d="M1.5 13C0.671573 13 0 12.3284 0 11.5C0 10.6716 0.671573 10 1.5 10C2.32843 10 3 10.6716 3 11.5C3 12.3284 2.32843 13 1.5 13Z"
      fill={IconsColors[variable]}
    />
  </svg>
)

export default SvgComponent
