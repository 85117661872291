import { useMutation } from '@tanstack/react-query'
import statTableApi from '../index'

export const UPDATE_STATTABLE_DATA = 'StatTables_UpdateTableData'

const updateStatTableData = async (payload: { [key: string]: any }) => {
  const { id, ...data } = payload
  return statTableApi.put(`/${id}`, data).then(response => response.data)
}

const useUpdateStatTableData = () => {
  return useMutation(updateStatTableData, {
    mutationKey: [UPDATE_STATTABLE_DATA],
  })
}

export { useUpdateStatTableData }
