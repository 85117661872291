import React, { useCallback, useEffect, useRef } from 'react'
import { flexRender } from '@tanstack/react-table'
import { useUpdateStatTableData } from '@mm/api/src/stattables/requests/useUpdateStatTable'
import { useGetStatTableData } from '@mm/api/src/stattables/requests/useGetStatTable'
import { useIsMounted } from '@mm/utils'
import { WidgetWrapper } from '@mm/ui/src/components/WidgetWrapper'
import { tableIconSource } from '@mm/ui/src/components/WidgetIcon/utils'
import { SharedTable } from '@mm/ui/src/components/SharedTable'
import { TableBodyCell } from '@mm/ui/src/components/SharedTable/styles'
import { getFormattedValueBySource, parseError } from './utils'
import { StatTableProps } from './types'

const StatTable = ({
  loading,
  name,
  onDuplicate,
  onEdit,
  onDelete,
  editLayout,
  metrics,
  id,
}: StatTableProps) => {
  const {
    data,
    isFetching,
    error: fetchError,
    refetch,
  } = useGetStatTableData(id)
  const error = parseError(data)
  const tableRef = useRef(null)
  const { mutate: updateStatTableSettings } = useUpdateStatTableData()
  const isMounted = useIsMounted()

  useEffect(() => {
    if (isMounted()) refetch()
  }, [refetch, isMounted])

  const handleUpdateStatTableSettings = useCallback(
    configurations => {
      const payload = { id, ...configurations }
      updateStatTableSettings(payload)
    },
    [id, updateStatTableSettings]
  )

  useEffect(() => {
    if (loading && tableRef.current) {
      window.scrollTo({
        top: tableRef.current.offsetTop,
        behavior: 'smooth',
      })
    }
  }, [loading])

  return (
    <WidgetWrapper
      widgetId={id}
      componentId="table"
      title={name}
      showIcons={data?.dashboard_widgets?.show_icons}
      iconsSource={tableIconSource(metrics)}
      onDelete={onDelete}
      onEdit={onEdit}
      onDuplicate={onDuplicate}
      isLoading={loading || isFetching}
      fetchError={fetchError}
      parsedError={error}
    >
      {!loading && (
        <SharedTable
          headings={data?.headings || ['Metric']}
          rows={data?.rows || []}
          errors={data?.errors || []}
          editLayout={editLayout}
          updateTableSettings={handleUpdateStatTableSettings}
          configurations={data?.dashboard_widgets?.configurations}
        >
          {({ cell, virtualRow }) => {
            const cellValue = cell.getValue()
            const metric = metrics[virtualRow.index]
            const valueFormatted = getFormattedValueBySource(cellValue, metric)
            const { warning, error } = cellValue

            return (
              <TableBodyCell
                key={cell.id}
                title={warning || error || ''}
                hoverMessage={warning || error}
                warning={warning}
                style={{ width: cell.column.getSize() }}
              >
                {flexRender(valueFormatted, cell.getContext())}
              </TableBodyCell>
            )
          }}
        </SharedTable>
      )}
    </WidgetWrapper>
  )
}

export { StatTable }
