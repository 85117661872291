import { useRef } from 'react'
import { useVirtualizer } from '@tanstack/react-virtual'
import { UseTableVirtualizationProps, VirtualPadding } from './types'

const useTableVirtualization = ({
  rows,
  columns,
  overscan = 10,
  columnEstimateSize,
  columnCount,
  rowEstimateSize = 45,
  visibleColumns,
}: UseTableVirtualizationProps) => {
  const tableWrapperRef = useRef<HTMLDivElement>(null)

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    overscan,
    count: columnCount || columns.length,
    estimateSize: index =>
      columnEstimateSize?.(index) ?? visibleColumns[index].getSize(),
    getScrollElement: () => tableWrapperRef.current,
  })

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    overscan,
    estimateSize: () => rowEstimateSize,
    getScrollElement: () => tableWrapperRef.current,
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
  })

  const virtualColumns = columnVirtualizer.getVirtualItems()
  const virtualRows = rowVirtualizer.getVirtualItems()

  let virtualPaddingLeft: VirtualPadding
  let virtualPaddingRight: VirtualPadding

  if (columnVirtualizer && virtualColumns?.length) {
    virtualPaddingLeft = virtualColumns[0]?.start ?? 0
    virtualPaddingRight =
      columnVirtualizer.getTotalSize() -
      (virtualColumns[virtualColumns.length - 1]?.end ?? 0)
  }

  return {
    tableWrapperRef,
    rowVirtualizer,
    columnVirtualizer,
    virtualPaddingLeft,
    virtualPaddingRight,
    virtualRows,
    virtualColumns,
  }
}

export { useTableVirtualization }
