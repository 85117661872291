import React, { createContext, ReactNode, useContext } from 'react'

const ExportPdfContext = createContext()

const ExportPdfProvider = ({
  children,
  isExportPdf,
}: {
  children: ReactNode
  isExportPdf: boolean
}) => {
  return (
    <ExportPdfContext.Provider value={isExportPdf}>
      {children}
    </ExportPdfContext.Provider>
  )
}

const useExportPdf = () => useContext(ExportPdfContext)

export { ExportPdfProvider, useExportPdf }
