import { useQuery } from '@tanstack/react-query'
import statTableApi from '../index'

export const GET_STATTABLE_DATA_KEY = 'StatTables_GetTableData'

export const getStatTableData = async (id: string) => {
  return statTableApi.get(`/${id}/data`).then(response => response.data?.data)
}

const useGetStatTableData = id =>
  useQuery([GET_STATTABLE_DATA_KEY, id], () => getStatTableData(id), {
    staleTime: 1000 * 60 * 60, // 1 hour
    refetchOnWindowFocus: false,
    retry: false,
  })

export { useGetStatTableData }
