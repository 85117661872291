// @ts-nocheck
import { format } from 'date-fns'
import {
  monetaryMetrics,
  numberMetrics,
  percentageMetrics,
} from '../Powertable/constants'
import { DATE_FNS_FORMAT } from '../SingleDatePicker/SingleDatePicker'
import { Format, FILTERS_CALLBACKS } from './Constants'
import { ColData, RowData, ColumnReporting } from './types'
import formatNumber from 'format-number'
import { getDefaultDateValues } from './ComparedValueMarker/calculateDate'

export function getStoredColumnValue(col: ColData, id: string): string | null {
  const data = col.data
  if (typeof data === 'object' && data !== null && 'stored' in data) {
    return data.stored[id] || null
  }

  return null
}

export function getValueRowData(
  row: RowData,
  key: string,
  currentValue = true,
  dateType?: string
): string {
  const defaultDateValue = getDefaultDateValues(dateType)
  if (defaultDateValue) return defaultDateValue
  const data = row ? row[key] : '-'
  const isString = typeof data === 'string'
  if (isString) return data as string

  if (key === 'id' && data) return (data as { current: string }).current
  if (currentValue && data) return (data as { current: string }).current
  if (!currentValue && data) return (data as { current: string }).comparedValue
}

export function sortColumn(
  property: { current: string } | string | null,
  type = 'string'
) {
  let sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a: ColumnReporting, b: ColumnReporting) {
    let result

    if (property === 'order') {
      result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    } else if (type === 'number') {
      const valueA = a[property]
        ? Number(a[property].current ?? a[property] ?? 0)
        : 0
      const valueB = b[property]
        ? Number(b[property].current ?? b[property] ?? 0)
        : 0
      result = valueA < valueB ? -1 : valueA > valueB ? 1 : 0
    } else if (type === 'string') {
      const valueA = a[property]
        ? (a[property].current ?? a[property] ?? '')
        : ''
      const valueB = b[property]
        ? (b[property].current ?? b[property] ?? '')
        : ''
      result = valueA < valueB ? -1 : valueA > valueB ? 1 : 0
    } else if (type === 'date') {
      const valueA = a[property]
        ? new Date(a[property].current ?? a[property] ?? 0)
        : 0
      const valueB = b[property]
        ? new Date(b[property].current ?? b[property] ?? 0)
        : 0
      result = valueA < valueB ? -1 : valueA > valueB ? 1 : 0
    }
    return result * sortOrder
  }
}

export function formatData(
  data: string | null,
  column: ColData,
  decimals = 2,
  numberType = 'number'
) {
  if (!data) return null

  if (isNaN(data)) {
    return data
  }

  const columnValue = (column.data.value || '').toLowerCase()
  const isFormula = column.type === 'formula'
  if (column.data.numberType === 'date') {
    return format(new Date(+data || 0), DATE_FNS_FORMAT)
  }
  if (column.data.numberType === 'number') {
    const numberFormat = formatNumber({
      round: decimals ?? 0,
      padRight: decimals ?? 0,
    })
    return numberFormat(+data)
  }
  if (
    // ! Reveeer porcentaje y currency automatico de formulas
    (((columnValue.includes('ctr') && !columnValue.includes('electrnico')) ||
      columnValue === 'conversions_from_interactions_rate') &&
      !isFormula) ||
    (numberType === 'percentage' && isFormula) ||
    columnValue.includes('rate') ||
    percentageMetrics.includes(column.data.value)
  ) {
    return Number(data).toFixed(decimals) + '%'
  }

  if (columnValue === 'searchimpressionshare' && !isFormula) {
    if (data < 0.1) return '< 10%'

    return (Number(data) * 100).toFixed(decimals) + '%'
  }

  if (
    ((columnValue.includes('amount') ||
      columnValue.includes('cpm') ||
      columnValue.includes('cpc') ||
      columnValue.includes('cost') ||
      columnValue.includes('revenue') ||
      columnValue.endsWith('_value') ||
      columnValue.endsWith('_total') ||
      columnValue.endsWith('_subtotal') ||
      columnValue.endsWith('_price') ||
      columnValue.endsWith('_ticket') ||
      monetaryMetrics.includes(columnValue) ||
      columnValue === 'conversionsvalue') &&
      !isFormula &&
      !numberMetrics.includes(columnValue)) ||
    (numberType === 'currency' && isFormula)
  ) {
    return Number(data).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimals ?? 2,
      maximumFractionDigits: decimals ?? 2,
    })
  }

  return Number(data).toLocaleString('en-US', {
    minimumFractionDigits: decimals ?? 2,
    maximumFractionDigits: decimals ?? 2,
  })
}

export function getRowConditionalFormat(
  data: any,
  conditional_format: Format[],
  type: 'client' | 'campaign',
  columns: ColData[]
) {
  const currentId = data.id?.current
  const filterConditionalFormats = conditional_format.filter(
    cond => (cond.campaigns.length || cond.clients?.length) && cond.applyToRow
  )

  if (!filterConditionalFormats.length) return data
  const rowFormat = {}
  for (const conditionalFormat of filterConditionalFormats) {
    if (type === 'campaign') {
      if (!conditionalFormat.campaigns.find(camp => camp === currentId)) {
        continue
      }
    } else {
      if (!conditionalFormat.clients.includes(currentId)) {
        continue
      }
    }
    const column = columns.find(c => conditionalFormat?.field?.id === c.id)
    if (!column) continue

    if (conditionalFormat.conditions.length) {
      const reverseConditions = [...conditionalFormat.conditions].reverse()

      for (const condition of reverseConditions) {
        if (
          condition.operator &&
          FILTERS_CALLBACKS[condition.operator.value](
            (data[column.data.value].current || data[column.data.value] || '')
              .toString()
              .toLowerCase(),
            condition.value.toLowerCase()
          )
        ) {
          rowFormat.backgroundColor = condition.color
          rowFormat.fontWeight = condition.fontWeight
            ? condition.fontWeight
            : '400'
          rowFormat.color = condition.fontColor
            ? condition.fontColor
            : '#1B215C'
        }
      }
    }
  }

  data.rowFormat = rowFormat

  return data
}

export function conditionalFormatData(
  data: {
    [column: string]: string | null
  },
  column: ColData,
  conditional_format: Format[],
  type: 'campaign' | 'client'
) {
  if (!data || !conditional_format?.length) return {}

  const columnName = column?.data?.value
  let accountId
  const columnId = column?.id
  if (type === 'campaign') {
    accountId = data.accountId.current
  }
  const currentId = data.id.current
  const dataValue = (
    data[column?.data?.value]?.current ||
    data[column?.data?.value] ||
    ''
  ).toString()

  return conditional_format.reduce((finalFormat, format) => {
    if (data.rowFormat && Object.keys(data.rowFormat).length) {
      return data.rowFormat
    }

    if (type === 'campaign') {
      if (
        !format.adAccountsAll &&
        !format.adAccounts.find(account => account === accountId)
      ) {
        return finalFormat
      }
      if (
        !format.campaignsAll &&
        !format.campaigns.find(camp => camp === currentId)
      ) {
        return finalFormat
      }
    } else {
      if (
        !format.clientsAll &&
        !format.clients.find(client => client === currentId)
      ) {
        return finalFormat
      }
    }
    if (!columnName || format.field.id !== columnId) {
      return finalFormat
    }

    if (format.conditions.length) {
      const reverseConditions = [...format.conditions].reverse()

      for (const condition of reverseConditions) {
        if (
          condition.operator &&
          FILTERS_CALLBACKS[condition.operator.value](
            dataValue.toLowerCase(),
            condition.value.toLowerCase()
          )
        ) {
          finalFormat.backgroundColor = condition.color
          finalFormat.fontWeight = condition.fontWeight
            ? condition.fontWeight
            : '400'
          finalFormat.color = condition.fontColor
            ? condition.fontColor
            : '#1B215C'
        }
      }
    }
    return finalFormat
  }, {})
}

export const removeFixedColumn = (
  column: ColData,
  columns: ColData[],
  isFixedColum: (c: ColData) => boolean
) => {
  const deleteFixedColOrder = column.data.fixedOrder ?? 0
  return columns.map(c => {
    if (!isFixedColum(c)) return c
    const currentFixedOrder = c.data.fixedOrder ?? 0
    if (currentFixedOrder > deleteFixedColOrder) {
      return {
        ...c,
        data: { ...c.data, fixedOrder: currentFixedOrder - 1 },
      }
    }
    if (c.data.value !== column.data.value) return c
    return {
      ...c,
      data: { ...c.data, fixedOrder: undefined },
    }
  })
}

export const addFixedColumn = (
  column: ColData,
  columns: ColData[],
  isFixedColum: (c: ColData) => boolean
) => {
  const fixedColumns = columns.filter(c => isFixedColum(c))

  return columns.map(c => {
    if (c.data.value !== column.data.value) return c

    return {
      ...c,
      data: { ...c.data, fixedOrder: fixedColumns.length },
    }
  })
}

export const orderFixedColumn = (
  column: ColData,
  columns: ColData[],
  isFixedColum: (c: ColData) => boolean,
  dir: string
) => {
  const columnFixedOrder = column.data.fixedOrder ?? 0

  if (dir === 'right') {
    const rightColumn = columns.find(
      c => c.data.fixedOrder === columnFixedOrder + 1
    )
    if (!rightColumn) return null
    rightColumn.data.fixedOrder = columnFixedOrder
    column.data.fixedOrder = columnFixedOrder + 1
  }

  if (dir === 'left') {
    const leftColumn = columns.find(
      c => c.data.fixedOrder === columnFixedOrder - 1
    )
    if (!leftColumn) return null
    leftColumn.data.fixedOrder = columnFixedOrder
    column.data.fixedOrder = columnFixedOrder - 1
  }

  return columns
}
