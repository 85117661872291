import React from 'react'
import { flexRender } from '@tanstack/react-table'
import { WidgetWrapper } from '@mm/ui/src/components/WidgetWrapper'
import { SharedTable } from '@mm/ui/src/components/SharedTable'
import { TableBodyCell } from '@mm/ui/src/components/SharedTable/styles'
import { formatMetric } from '@mm/ui/src/components/Powertable/utils'
import { RowObject, SimpleTableComponentProps } from './types'

const SimpleTable = ({
  id,
  isFetching,
  isPublic,
  dateChosen,
  dateFormat,
  fetchError,
  errorsData,
  response,
  editLayout,
  updateSimpleTableSettings,
  onEdit,
  onDelete,
  onDuplicate,
}: SimpleTableComponentProps) => (
  <WidgetWrapper
    widgetId={id}
    componentId="simpletable"
    title={response?.name}
    dateLabel={dateFormat}
    showIcons={response?.data.show_icons}
    iconsSource={response?.data.source}
    onDelete={onDelete}
    onEdit={onEdit}
    onDuplicate={onDuplicate}
    isLoading={isFetching}
    fetchError={fetchError}
    errorsData={errorsData}
    isPublic={isPublic}
    dateChosen={dateChosen}
  >
    {
      <SharedTable
        headings={response?.data.headings || ['Metric']}
        rows={response?.data.rows || []}
        errors={response?.data.errors || []}
        editLayout={editLayout}
        configurations={response?.configurations}
        updateTableSettings={updateSimpleTableSettings}
      >
        {({ cell }) => {
          const cellValue = cell.getValue() as RowObject
          const { warning, error, type, value } = cellValue
          const metricValue = type ? formatMetric(type, value || 0) : 0

          return (
            <TableBodyCell
              key={cell.id}
              title={warning || error || ''}
              hoverMessage={warning || error}
              warning={warning}
              style={{ width: cell.column.getSize() }}
            >
              {flexRender(metricValue, cell.getContext())}
            </TableBodyCell>
          )
        }}
      </SharedTable>
    }
  </WidgetWrapper>
)

export { SimpleTable }
