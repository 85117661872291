import styled from 'styled-components'

export const ActionMenuButton = styled.button`
  position: relative;
  margin-left: auto;
  padding-bottom: 0.5rem;
  background: none;
  border: none;
  visibility: visible;

  @media (min-width: 992px) {
    visibility: hidden;
  }
`
