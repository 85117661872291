import React from 'react'
import { flexRender } from '@tanstack/react-table'
import { SortOrderButton } from '@mm/ui/src/components/SharedTable/utils'
import { ColResizer } from '@mm/ui/src/components/SharedTable/styles'
import { TableHeaderCell } from './styles'
import { TableHeaderCellProps } from './types'

export const customSortingFn = (rowA, rowB, columnId) => {
  const valueA = parseFloat(rowA.getValue(columnId) || 0)
  const valueB = parseFloat(rowB.getValue(columnId) || 0)
  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0
}

export const HeaderCell = ({
  colSpan,
  column,
  table,
  isSticky = false,
  isPlaceholder,
  stickyLeft,
  windowWidth,
  isExportPdf,
  getSize,
  getContext,
  getResizeHandler,
}: TableHeaderCellProps) => (
  <TableHeaderCell
    colSpan={colSpan}
    style={{
      width: getSize(),
      display: 'flex',
      justifyContent: isSticky ? 'flex-start' : 'center',
      ...(isExportPdf ? {} : { left: windowWidth > 1023 ? stickyLeft : 0 }),
    }}
    title={
      typeof column.columnDef.header === 'string'
        ? column.columnDef.header || ''
        : ''
    }
    isExportPdf={isExportPdf}
    isSticky={isSticky}
    className={`${column.columnDef?.meta?.className ?? ''} ${isPlaceholder ? 'isPlaceholder' : ''}`}
  >
    <span>
      {isPlaceholder ? null : flexRender(column.columnDef.header, getContext())}
    </span>
    {isPlaceholder || isExportPdf
      ? null
      : column.getCanSort() && <SortOrderButton column={column} />}

    {isPlaceholder || isExportPdf ? null : (
      <ColResizer
        onDoubleClick={() => column.resetSize()}
        onMouseDown={getResizeHandler()}
        onTouchStart={getResizeHandler()}
        className={`${table.options.columnResizeDirection} ${column.getIsResizing() ? 'isResizing' : ''}`}
      />
    )}
  </TableHeaderCell>
)
