import React, { useState, useEffect, useMemo, useCallback } from 'react'
import useGetSimpletableData from '@mm/api/src/simpletables/requests/useGetSimpletableData'
import { useIsMounted } from '@mm/utils'
import { useUpdateSimpletableData } from '@mm/api/src/simpletables/requests/useUpdateSimpletableData'
import { calculateDateRange } from '@mm/ui/src/helpers/widgetDate'
import { SimpleTable as SimpleTableComponent } from './SimpleTable'
import { GetSimpleTableData, SimpleTableProps } from './types'

const SimpleTable = ({
  id,
  dashboard,
  shareable,
  dateChosen,
  pdf,
  editLayout,
  onEdit,
  onDelete,
  onDuplicate,
}: SimpleTableProps) => {
  const [dateFormat, setDateFormat] = useState('')
  const isPublic = !!shareable || !!pdf
  const { mutate: updateSimpleTableSettings } = useUpdateSimpletableData()
  const isMounted = useIsMounted()

  const config = {
    id,
    shareable,
    pdf,
    dashboard,
  }

  const {
    data: response,
    error: fetchError,
    isFetching,
    refetch,
  }: GetSimpleTableData = useGetSimpletableData(config)

  useEffect(() => {
    if (isMounted()) refetch()
  }, [refetch, isMounted])

  const handleUpdateSimpleTableSettings = useCallback(
    configurations => {
      const payload = { id, ...configurations }
      updateSimpleTableSettings(payload)
    },
    [id, updateSimpleTableSettings]
  )

  useEffect(() => {
    if (dateChosen && response) {
      setDateFormat(calculateDateRange(dateChosen, response))
    }
  }, [dateChosen, response])

  const errorsData = useMemo(() => {
    if (!response) return undefined

    const errors = response?.data?.errorsData ?? []
    if (!errors.length) return

    return (
      errors.find((error: any) => error?.message) || {
        message: 'Something went wrong, please contact support',
      }
    )
  }, [response])

  return (
    <SimpleTableComponent
      id={id}
      isFetching={isFetching}
      isPublic={isPublic}
      dateChosen={dateChosen}
      dateFormat={dateFormat}
      fetchError={fetchError}
      errorsData={errorsData}
      response={response}
      editLayout={editLayout}
      onEdit={onEdit}
      onDelete={onDelete}
      onDuplicate={onDuplicate}
      updateSimpleTableSettings={handleUpdateSimpleTableSettings}
    />
  )
}

export { SimpleTable }
