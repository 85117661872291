import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable, width = '24', height = '24' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3090_29378)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.8325 0.0640259C50.4482 0.0640259 52.5686 2.1844 52.5686 4.80002V16.2113H75.3911V4.80002C75.3911 2.1844 77.5115 0.0640259 80.1271 0.0640259C82.7427 0.0640259 84.8631 2.1844 84.8631 4.80002V16.2113H106.353C109.296 16.2113 111.681 18.5967 111.681 21.5393V43.0966H123.2C125.816 43.0966 127.936 45.217 127.936 47.8326C127.936 50.4482 125.816 52.5686 123.2 52.5686H111.681V70.0087H123.2C125.816 70.0087 127.936 72.1291 127.936 74.7447C127.936 77.3604 125.816 79.4807 123.2 79.4807H111.681V106.353C111.681 109.296 109.296 111.681 106.353 111.681H84.8631V123.2C84.8631 125.816 82.7427 127.936 80.1271 127.936C77.5115 127.936 75.3911 125.816 75.3911 123.2V111.681H52.5686V123.2C52.5686 125.816 50.4482 127.936 47.8325 127.936C45.2169 127.936 43.0965 125.816 43.0965 123.2V111.681H21.5393C18.5968 111.681 16.2113 109.296 16.2113 106.353V79.4807H4.79996C2.18434 79.4807 0.0639648 77.3604 0.0639648 74.7447C0.0639648 72.1291 2.18434 70.0087 4.79996 70.0087H16.2113V52.5686H4.79996C2.18434 52.5686 0.0639648 50.4482 0.0639648 47.8326C0.0639648 45.217 2.18434 43.0966 4.79996 43.0966H16.2113V21.5393C16.2113 18.5968 18.5967 16.2113 21.5393 16.2113H43.0965V4.80002C43.0965 2.1844 45.2169 0.0640259 47.8325 0.0640259ZM25.6833 102.209V25.6833H102.209V102.209H25.6833Z"
          fill={variable ? IconsColors[variable] : '#d9d9d9'}
        />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          fill={variable ? IconsColors[variable] : '#d9d9d9'}
          fontSize="64"
          fontWeight="bold"
          fontFamily="Arial, sans-serif"
          dy=".3em"
        >
          !
        </text>
      </g>
      <defs>
        <clipPath id="clip0_3090_29378">
          <rect width={width} height={height} fill="none" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgComponent
