import {
  formatDuration,
  isHubspotDuration,
  formatGoogleAnalyticsValue,
  formatGoogleValue,
  formatLinkedinValue,
  formatMetaValue,
  formatValue,
  formatCalculatedMetric,
  formatTiktokValue,
} from '@mm/utils/src/helpers/Utils'
import { isCalculatedMetric } from '../../helpers/calculatedMetrics'
import { FormatFunctionsReturn } from './types'

export const getFormattedValueBySource = (cell, metric) => {
  const formatFunctions: FormatFunctionsReturn = {
    AM: formatValue,
    META: formatMetaValue,
    GOOGLE: formatGoogleValue,
    GOOGLE_ANALYTICS: formatGoogleAnalyticsValue,
    LINKEDIN: formatLinkedinValue,
    TIKTOK: formatTiktokValue,
  }

  if (isCalculatedMetric(metric?.type)) {
    if (metric.numberType !== 'default') {
      return formatCalculatedMetric(cell.value, metric)
    }
    if (['META', 'GOOGLE', 'GOOGLE_ANALYTICS'].includes(cell.source)) {
      const formatMetric = { ...metric, type: cell.numerator }
      return formatFunctions[cell.source as string](cell.value, formatMetric)
    }
  }

  if (metric.type && formatFunctions[metric.type]) {
    return formatFunctions[metric.type](cell.value, metric)
  }
  if (metric.source && formatFunctions[metric.source]) {
    return formatFunctions[metric.source](cell.value, metric)
  }

  if (isHubspotDuration(metric)) {
    return formatDuration(cell.value)
  }

  return cell.value
}

export const parseError = (data): string | undefined => {
  const rows = data?.rows

  if (rows) {
    for (const row of rows) {
      for (const cell of row) {
        if (cell.error) return cell.error
      }
    }
  }

  return undefined
}
