import { ColumnReporting } from '../../types'
import { useTablesContext } from '../tables.context'

export function useTablesColumns() {
  const { state, dispatch } = useTablesContext()

  const setColumns = (columns: ColumnReporting[]) => {
    dispatch({ type: 'SET_COLUMNS', payload: columns })
  }

  return {
    columns: state.columns,
    activeColumns: state.activeColumns,
    setColumns,
  }
}

export type ColumnsActions = {
  type: 'SET_COLUMNS'
  payload: ColumnReporting[]
}
