import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({
  variable,
  width = '1.2em',
  height = '1.2em',
}: {
  variable: keyof typeof IconsColors
  width?: string
  height?: string
}) => {
  return (
    <svg
      width={width}
      height={height}
      stroke={IconsColors[variable]}
      style={{ verticalAlign: 'middle', overflow: 'hidden' }}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M512 625.778c-159.289 0-284.444-125.156-284.444-284.445S352.71 56.89 512 56.89s284.444 125.155 284.444 284.444S671.29 625.778 512 625.778z m0-56.89c125.156 0 227.556-102.4 227.556-227.555S637.156 113.778 512 113.778s-227.556 102.4-227.556 227.555S386.844 568.89 512 568.89zM796.444 1024h-56.888V625.778h56.888V1024zM568.89 853.333v-56.889H967.11v56.89H568.89z"
        fill={IconsColors[variable]}
      />
      <path
        d="M512 625.778c-221.867 0-398.222 176.355-398.222 398.222h-56.89c0-250.311 204.8-455.111 455.112-455.111"
        fill={IconsColors[variable]}
      />
    </svg>
  )
}
export default SvgComponent
