import React, { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import actionsIcon from '../../assets/icons/chevron-down.svg'
import editIcon from '../../assets/icons/edit.svg'
import compareIcon from '../../assets/icons/compare.svg'
import orderIcon from '../../assets/icons/order.svg'
import orderAscendingIcon from '../../assets/icons/order-ascending.svg'
import orderDescendingIcon from '../../assets/icons/order-descending.svg'

import deleteIcon from '../../assets/icons/delete.svg'
import eyeInsivisbleIcon from '../../assets/icons/eye-invisible-icon.svg'
import { DropdownIcon } from './Styles'
import { ColumnTypes } from './types'
import { useOpenMenu } from './context/hooks/useOpenMenu'

interface Props {
  col: string
  colType: string
  onOrderColumn: Function
  order?: string
  isOrdered?: boolean
  onDeleteColumn?: Function
  onEditColumn?: () => void
  columnIndex: number
  onCompareColumn?: (
    col: string,
    compare: {
      enabled: boolean
      period: string
      from: Date | null
      to: Date | null
    }
  ) => void
  isCompared?: boolean
  isComparable?: boolean
  isFixed?: boolean
  isFixeadble?: boolean
  onFixedColumn?: (sourceIndex: number, dir?: string) => void
  overviewType: 'campaign' | 'client'
}

const SmartTableActions: React.FC<Props> = ({
  col,
  colType,
  onOrderColumn,
  order,
  isOrdered,
  onDeleteColumn,
  onEditColumn,
  isCompared,
  onCompareColumn,
  isComparable,
  isFixed,
}) => {
  const { openMenu, setOpenMenu } = useOpenMenu()
  const [isOpen, setIsOpen] = useState(false)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(openMenu === col)
    }, 150)

    return () => {
      clearTimeout(timer)
    }
  }, [col, openMenu])

  let orderIconSrc: string | undefined
  if (isOrdered) {
    switch (order) {
      case 'ASC':
        orderIconSrc = orderAscendingIcon
        break
      case 'DESC':
        orderIconSrc = orderDescendingIcon
        break
      default:
        orderIconSrc = orderIcon
        break
    }
  } else {
    orderIconSrc = orderIcon
  }

  const compareBtnStyle = {
    pointerEvents: 'all' as any,
    width: 32,
    padding: 4,
    margin: 0,
    ...(isCompared
      ? {
          filter:
            'invert(31%) sepia(96%) saturate(1177%) hue-rotate(218deg) brightness(87%) contrast(110%)',
        }
      : {}),
  }

  return (
    <Dropdown
      col={col}
      className="dashboard-actions dashboard-select"
      direction="up"
      isOpen={isOpen}
      toggle={() => {
        if (!isOpen) setOpenMenu(col!)
        if (isOpen) setOpenMenu('')
      }}
      style={{ margin: 0 }}
    >
      <DropdownToggle
        className="dashboard-actions-btn"
        data-toggle="dropdown"
        tag="span"
      >
        <div className="actions-icon" style={{ margin: 0 }}>
          <img
            alt=""
            src={actionsIcon}
            style={{ width: 24, height: 24, paddingTop: 2, paddingBottom: 2 }}
            className={`buttons-img`}
            onClick={() => setIsOpen(true)}
            draggable="false"
          />
        </div>
      </DropdownToggle>
      <DropdownMenu className="dashboard-select-options actions overview-cell-actions">
        <div>
          {onEditColumn && (
            <span style={{ margin: 2 }} title="Edit column">
              <img
                alt=""
                src={editIcon}
                className={`buttons-img preload-animation`}
                onClick={onEditColumn}
              />
            </span>
          )}
          {isComparable && onCompareColumn && (
            <span
              style={{ margin: 2 }}
              title={isCompared ? 'Disable Compare' : 'Enable Compare'}
            >
              <img
                alt=""
                src={compareIcon}
                className={`buttons-img preload-animation`}
                style={compareBtnStyle}
                onClick={() => {
                  setOpenMenu('')
                  onCompareColumn(col, {
                    enabled: !isCompared,
                    period: 'Previous period',
                    from: null,
                    to: null,
                  })
                }}
              />
            </span>
          )}
          {col !== 'client_name' && (
            <span style={{ margin: 2 }} title="Order column">
              <DropdownIcon
                alt=""
                src={orderIconSrc}
                style={{ pointerEvents: 'all' }}
                onClick={() => {
                  onOrderColumn(col)
                }}
              />
            </span>
          )}
          {!['campaign_name', 'client_name'].includes(col) &&
            !isFixed &&
            onDeleteColumn && (
              <span
                className="delete-dashboard"
                style={{ margin: 2, pointerEvents: 'all' }}
                title={`${
                  ColumnTypes.METRIC === colType ? 'Remove' : 'Hide'
                } column`}
              >
                <img
                  alt=""
                  src={
                    ColumnTypes.METRIC === colType
                      ? deleteIcon
                      : eyeInsivisbleIcon
                  }
                  className={`buttons-img preload-animation ${
                    ColumnTypes.METRIC === colType ? 'delete' : 'hide'
                  }`}
                  style={{ pointerEvents: 'all' }}
                  onClick={() => {
                    setOpenMenu('')
                    onDeleteColumn(col)
                  }}
                />
              </span>
            )}
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default SmartTableActions
