import React from 'react'
import { flexRender } from '@tanstack/react-table'
import { useWindowWidth } from '@mm/ui/src/hooks/useWindowWidth'
import { useExportPdf } from '@mm/ui/src/components/GridLayout/ExportPdfProvider'
import {
  renderEmptyState,
  renderErrorState,
} from '@mm/ui/src/components/SharedTable/utils'
import {
  Table as TableStyled,
  TableHead,
  TableHeaderRow,
  TableWrapper,
} from '@mm/ui/src/components/SharedTable/styles'
import { ImageRowContent } from './ImageRowContent'
import { HeaderCell } from './utils'
import { TableBody, TableBodyRow, TableBodyHeaderCell } from './styles'
import { TableComponentProps } from './types'

const Table = ({
  tableWrapperRef,
  table,
  columns,
  rowVirtualizer,
  virtualRows,
  firstColumnGroupValues,
  isImageTemplate,
  imageAdditionalData,
  errors,
  children,
}: TableComponentProps) => {
  const windowWidth = useWindowWidth()
  const isExportPdf = useExportPdf()

  const { rows: tableRows } = table.getRowModel()

  return (
    <TableWrapper
      ref={tableWrapperRef}
      style={{ direction: table.options.columnResizeDirection }}
      isExportPdf={!!isExportPdf}
    >
      <TableStyled hover style={{ width: table.getCenterTotalSize() }}>
        <TableHead>
          {table?.getHeaderGroups().map(({ id, headers }) => {
            if (!headers.length) return null

            let stickyLeft = 0

            return (
              <TableHeaderRow key={id}>
                {headers.map(
                  ({
                    id,
                    column,
                    colSpan,
                    isPlaceholder,
                    getSize,
                    getContext,
                    getResizeHandler,
                  }) => {
                    const isFirstGroup =
                      column.columnDef?.id?.includes('first-column-') ||
                      column.columnDef?.accessorKey?.includes('first-column-')

                    const isSticky = isFirstGroup
                    const currentLeft = isSticky ? stickyLeft : 0
                    if (isSticky) stickyLeft += column.getSize()

                    return (
                      <HeaderCell
                        key={id}
                        colSpan={colSpan}
                        column={column}
                        table={table}
                        isPlaceholder={isPlaceholder}
                        isSticky={isSticky}
                        stickyLeft={currentLeft}
                        windowWidth={windowWidth}
                        isExportPdf={!!isExportPdf}
                        getSize={getSize}
                        getContext={getContext}
                        getResizeHandler={getResizeHandler}
                      />
                    )
                  }
                )}
              </TableHeaderRow>
            )
          })}
        </TableHead>
        <TableBody style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
          {virtualRows.map(virtualRow => {
            const row = tableRows[virtualRow.index]
            const visibleCells = row.getVisibleCells()

            return (
              <TableBodyRow
                key={row.id}
                data-index={virtualRow.index}
                ref={node => rowVirtualizer.measureElement(node)}
                style={{ transform: `translateY(${virtualRow.start}px)` }}
                className="tbody"
              >
                <>
                  {visibleCells.map((cell, index) => {
                    if (!cell) return null

                    if (index < firstColumnGroupValues.size) {
                      const cellValue = cell.getValue() as string

                      const stickyLeft = visibleCells
                        .slice(0, index)
                        .reduce((acc, curr) => acc + curr.column.getSize(), 0)

                      const useVerticalImageRowContent =
                        isImageTemplate && cell.column.getSize() < 225

                      return (
                        <TableBodyHeaderCell
                          key={cell.id}
                          scope="row"
                          isExportPdf={!!isExportPdf}
                          style={{
                            width: cell.column.getSize(),
                            ...(isExportPdf ? {} : { left: stickyLeft }),
                          }}
                        >
                          {isImageTemplate ? (
                            <ImageRowContent
                              useVerticalImageRowContent={
                                useVerticalImageRowContent
                              }
                              isExportPdf={!!isExportPdf}
                              imageAdditionalData={imageAdditionalData}
                              cellValue={cellValue}
                            />
                          ) : (
                            <span title={`${cellValue ?? ''}`}>
                              {flexRender(cellValue, cell.getContext())}
                            </span>
                          )}
                        </TableBodyHeaderCell>
                      )
                    }
                    return children({ cell })
                  })}
                </>
              </TableBodyRow>
            )
          })}

          {!table?.getRowModel().rows.length &&
            !errors.length &&
            renderEmptyState(columns)}

          {renderErrorState(errors, columns.length)}
        </TableBody>
      </TableStyled>
    </TableWrapper>
  )
}

export { Table }
