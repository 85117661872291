import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  SetStateAction,
  Dispatch,
} from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { StickyGrid } from '../StickyGrid'
import { Format } from './Constants'
import {
  EmptyTableRow,
  InvisibleDiv,
  TableCell,
  TableEmptyMsg,
  TableRow,
} from './Styles'
import { SmartTableHeader } from './SmartTableHeader'
import { SmartTableCell } from './SmartTableCell'
import { conditionalFormatData, getRowConditionalFormat } from './Utils'
import { ColData, TableReporting, ColumnReporting } from './types'

interface Props {
  data: Array<{ [column: string]: string | null }>
  activeColumns: Array<ColumnReporting>
  columns: Array<ColumnReporting>
  condtional_format: Array<Format>
  onDataChange?: (
    tableData: Array<{ [column: string]: string | null }>,
    columns: Array<ColumnReporting>
  ) => void
  onChangeFormula?: (col: ColumnReporting, value: string, rowId: string) => void
  columnOrder: string | null
  orderedColumn: string | null
  onOrderChange?: Function
  onCompareChange?: (
    col: string,
    compare: {
      enabled: boolean
      period: string
      from: Date | null
      to: Date | null
    }
  ) => void
  onEditColumn?: (col: ColumnReporting) => void
  onEditMetricColumn?: (col: ColumnReporting) => void
  onColumnsResize?: (col: string, width?: number) => void
  updateCustomFieldData?: (props: {
    payload: any
    columnId: string
    rowId: string
    refresh?: boolean
  }) => void
  onHideColumn: (col: string) => void
  modalContained?: boolean
  style?: React.CSSProperties
  onChangeScroll?: (props: { left: number; top: number }) => void
  scrollPosition?: { left: number; top: number }
  table: TableReporting
  appliedView: any
  type: 'campaign' | 'client'
  EmptyState?: false | any
  setSecondaryEntityModal?: (id: string) => void
  handleDragEnd: (index: string, end: string) => void
  updateCheckboxValue?: (updateFieldProps: {
    value: boolean
    rowId: string
    column: ColData
    onError: () => void
  }) => void
  smartTableStyle?: { [key: string]: React.CSSProperties }
  disableEditColumn?: boolean
  disableRemoveColumn?: boolean
  allowEdit: boolean
  isPublic?: boolean
}

const SmartTable: React.FC<Props> = ({
  data: tableData,
  activeColumns,
  columns,
  condtional_format,
  onDataChange,
  columnOrder,
  orderedColumn,
  onOrderChange,
  onCompareChange,
  onColumnsResize,
  updateCustomFieldData,
  modalContained,
  onEditColumn,
  onEditMetricColumn,
  style,
  onChangeScroll,
  scrollPosition,
  onHideColumn,
  onChangeFormula,
  table,
  appliedView,
  type,
  EmptyState,
  setSecondaryEntityModal,
  handleDragEnd,
  updateCheckboxValue,
  smartTableStyle,
  disableEditColumn,
  disableRemoveColumn,
  allowEdit,
  isPublic,
}) => {
  const tableRef = useRef<HTMLDivElement>(null)

  const saveScroll = () => {
    const tableContainer = document.getElementsByClassName(
      'scrollbar-color-table'
    )[0]
    if (tableContainer) {
      onChangeScroll &&
        onChangeScroll({
          left: tableContainer.scrollLeft,
          top: tableContainer.scrollTop,
        })
    }
  }

  const handleOrderColumn = (value: string) => {
    onOrderChange && onOrderChange(value)
  }

  const handleCompareColumn = (
    col: string,
    compare: {
      enabled: boolean
      period: string
      from: Date | null
      to: Date | null
    }
  ) => {
    onCompareChange && onCompareChange(col, compare)
  }

  // Columns resizing logic
  const [initialPos, setInitialPos] = useState<any>(null)
  const [initialSize, setInitialSize] = useState<any>(null)

  const initial = (e: any, column: string) => {
    saveScroll()
    setInitialPos({ column: column, pos: e.clientX })
    setInitialSize({
      column: column,
      size: activeColumns.find((col: ColData) => col.data?.value === column)
        ?.width,
    })
    switch (e.detail) {
      case 2:
        if (
          (activeColumns.find(col => col.data?.value === column)?.width || 0) <
          220
        ) {
          onColumnsResize && onColumnsResize(column, 220)
        } else {
          onColumnsResize && onColumnsResize(column)
        }
        break
      default:
        break
    }
  }

  const resize = (e: any, column: string) => {
    saveScroll()
    const newSize = initialSize?.size + e.clientX - initialPos?.pos
    if (
      initialSize?.column === column &&
      initialPos?.column === column &&
      !isNaN(newSize)
    ) {
      onColumnsResize && onColumnsResize(column, newSize)
    }
  }

  const updateField = ({
    value,
    rowId,
    columnId,
    refresh,
  }: {
    value: string
    rowId: string
    columnId: number
    refresh?: boolean
  }) => {
    saveScroll()
    const columnsCopy = [...columns]
    const editedColumn = columnsCopy.find(column => column.id === columnId)
    if (!editedColumn) return
    editedColumn.data.stored = {
      ...editedColumn.data.stored,
      [rowId]: value,
    }
    onDataChange && onDataChange(tableData, columnsCopy)
    updateCustomFieldData &&
      updateCustomFieldData({
        payload: value,
        columnId: String(columnId),
        rowId,
        refresh,
      })
    const col = columns.find(c => c.id === columnId)
    if (col && ['result_formula', 'source_result'].includes(col.type)) {
      onChangeFormula && onChangeFormula(col, value, rowId)
    }
  }

  function handleHideColumn(col: string) {
    saveScroll()
    onHideColumn(col)
  }

  const columnWidth = useCallback(
    (index: number) => {
      const col = activeColumns[index]
      if (col) {
        const width = col.width ? Number(col.width) : 0
        return width - 8
      }
      return 0
    },
    [activeColumns]
  )

  const stickyGridKey = useMemo(() => {
    return activeColumns
      .map(
        c =>
          `${c.id}-${c.data.fixedOrder || c.data.fixedOrder === 0 ? 'fix' : ''}`
      )
      .join('-')
  }, [activeColumns])

  const fixedColumns = useMemo(() => {
    return activeColumns.filter((c, i) => c.data.fixedOrder || i === 0)
  }, [activeColumns])

  if (EmptyState) {
    return <EmptyState handleAddEntity={setSecondaryEntityModal}></EmptyState>
  }

  if (tableData.length === 0) {
    return (
      <EmptyTableRow>
        <TableEmptyMsg>{`0 ${type}s`}</TableEmptyMsg>
      </EmptyTableRow>
    )
  }
  return (
    <div style={{ height: '60vh' }}>
      <AutoSizer
        style={
          smartTableStyle?.AutoSizer
            ? smartTableStyle?.AutoSizer
            : {
                height: 'max-content',
                width: 'max-content',
                maxHeight: 40 * (tableData?.length || 1),
                marginTop: 10,
                filter: modalContained
                  ? 'drop-shadow(0px 6px 22px rgba(0, 0, 0, 0.08))'
                  : 'none',
                ...style,
              }
        }
      >
        {({ height, width }) => (
          <StickyGrid
            key={`StickyGrid-${stickyGridKey}`}
            columnCount={activeColumns.length}
            columnWidth={(index: number) => columnWidth(index)}
            height={height}
            rowCount={tableData?.length + 1}
            rowHeight={() => {
              return 40
            }}
            fixedColumns={fixedColumns.length}
            width={width}
            style={
              smartTableStyle?.StickyGrid
                ? smartTableStyle?.StickyGrid
                : { height: modalContained ? '86vh' : '56vh' }
            }
            className="scrollbar-color scrollbar-vertical-color scrollbar-color-table"
            ref={tableRef}
            initialScrollLeft={scrollPosition?.left || 0}
            initialScrollTop={scrollPosition?.top || 0}
          >
            {({
              columnIndex,
              rowIndex,
              style,
              isLastSticky,
              marginRight,
            }: {
              columnIndex: number
              rowIndex: number
              style: React.CSSProperties
              isLastSticky?: boolean
              marginRight?: string
            }) => {
              const column = activeColumns[columnIndex]
              if (!column) return null
              const isFirstColumn = columnIndex === 0
              const isLastColumn = columnIndex === activeColumns.length - 1
              const isBottomLeftCorner =
                isFirstColumn && rowIndex === tableData.length
              const isBottomRightCorner =
                isLastColumn && rowIndex === tableData.length
              let minWidth =
                (document.getElementById(`${column.data?.value}-title`)
                  ?.offsetWidth || 0) +
                (document.getElementById(`${column.data?.value}-actions`)
                  ?.offsetWidth || 0)
              minWidth = isNaN(minWidth) ? 220 : minWidth
              const maxWidth = columnWidth(columnIndex)
              const isFixedColumn = typeof column.data.fixedOrder === 'number'
              const isFixiable =
                column.type === 'metric' &&
                !['campaign_name', 'client_name'].includes(column.data.value)

              // HEADER
              if (column && rowIndex === 0) {
                return (
                  <SmartTableHeader
                    style={style}
                    column={column}
                    columnIndex={columnIndex}
                    isFirstColumn={isFirstColumn}
                    isLastColumn={isLastColumn}
                    orderedColumn={orderedColumn || ''}
                    columnOrder={columnOrder || ''}
                    onEditColumn={onEditColumn}
                    onEditMetricColumn={onEditMetricColumn}
                    disableEditColumn={disableEditColumn}
                    disableRemoveColumn={disableRemoveColumn}
                    saveScroll={saveScroll}
                    handleCompareColumn={handleCompareColumn}
                    handleOrderColumn={handleOrderColumn}
                    onColumnsResize={onColumnsResize}
                    handleDeleteColumn={handleHideColumn}
                    initial={initial}
                    resize={resize}
                    minWidth={minWidth}
                    maxWidth={maxWidth}
                    isFixed={columnIndex === 0 || isFixedColumn}
                    isFixeadble={isFixiable}
                    overviewType={type}
                  />
                )
              }

              // Value Cells
              const row = getRowConditionalFormat(
                tableData[rowIndex - 1],
                condtional_format,
                type,
                columns
              )
              const conditionalFormat = conditionalFormatData(
                row,
                column,
                condtional_format,
                type
              )

              return (
                <>
                  <TableRow
                    key={`${rowIndex - 1}-${columnIndex}`}
                    style={style}
                  >
                    <TableCell
                      onDragOver={e => {
                        if (columnIndex === 0 && type === 'client') {
                          e.currentTarget.classList.add('dragged-over')
                          e.preventDefault()
                        }
                      }}
                      onDragLeave={e => {
                        e.currentTarget.classList.remove('dragged-over')
                      }}
                      onDropCapture={e => {
                        if (columnIndex === 0 && type === 'client') {
                          e.currentTarget.classList.remove('dragged-over')
                          handleDragEnd(
                            e.dataTransfer.getData('text'),
                            (row.id as { current: string })?.current
                          )
                        }
                      }}
                      isFixedColumn={isFixedColumn}
                      style={{
                        borderBottomLeftRadius: isBottomLeftCorner ? 8 : 0,
                        borderBottomRightRadius: isBottomRightCorner ? 8 : 0,
                        width:
                          maxWidth -
                          (columnIndex === fixedColumns.length - 1 ? 3 : 0),
                        ...conditionalFormat,
                      }}
                    >
                      <SmartTableCell
                        row={row}
                        column={column}
                        errors={columnIndex === 0 && row.errors}
                        isPublic={isPublic}
                        columnIndex={columnIndex}
                        updateField={updateField}
                        updateCheckboxValue={updateCheckboxValue}
                        rowIndex={rowIndex}
                        columns={activeColumns}
                        table={table}
                        appliedView={appliedView}
                        isFixedColumn={isFixedColumn}
                        allowEdit={allowEdit}
                      />
                    </TableCell>
                  </TableRow>
                  {isLastSticky ? (
                    <InvisibleDiv marginRight={marginRight} />
                  ) : null}
                </>
              )
            }}
          </StickyGrid>
        )}
      </AutoSizer>
    </div>
  )
}

export default SmartTable
