import React from 'react'
import { flexRender } from '@tanstack/react-table'
import { useIntl } from 'react-intl'
import { StyledComponent } from 'styled-components'
import { useExportPdf } from '@mm/ui/src/components/GridLayout/ExportPdfProvider'
import IntlMessage from '@mm/utils/src/helpers/IntlMessages'
import {
  ColResizer,
  OrderButton,
  TableBodyCellData,
  TableBodyCellError,
  TableBodyRow,
  TableBodyRowError,
  TableHeaderCell,
} from './styles'
import { Size, Sorting, TableHeaderCellProps } from './types'

export const MIN_SIZE = 100
export const MAX_SIZE = 500

const CLEAR_SORT = { id: 'general.clear-sort' }

const ORDER_ICONS = {
  asc: '↑',
  desc: '↓',
  default: '↑↓',
}

const SORTING_ORDER = {
  asc: 'ASC',
  desc: 'DESC',
}

export const customSortingFn = (rowA, rowB, columnId) => {
  const valueA = parseFloat(rowA.getValue(columnId)?.value || 0)
  const valueB = parseFloat(rowB.getValue(columnId)?.value || 0)
  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0
}

export const normalizeColumnSizing = (sizing: Size) =>
  Object.fromEntries(
    Object.entries(sizing).map(([key, size]) => [
      key,
      Math.max(MIN_SIZE, Math.min(size, MAX_SIZE)),
    ])
  )

export const SortOrderButton = ({ column }) => {
  const { formatMessage } = useIntl()

  const nextSortingOrder: Sorting | false = column.getNextSortingOrder()
  const columnSorted: Sorting | false = column.getIsSorted()

  const title = column.getCanSort()
    ? SORTING_ORDER[nextSortingOrder as Sorting] || formatMessage(CLEAR_SORT)
    : undefined

  const sortingIcon =
    ORDER_ICONS[columnSorted as Sorting] ?? ORDER_ICONS.default

  return (
    <OrderButton
      type="button"
      onClick={column.getToggleSortingHandler()}
      title={title}
      className={columnSorted ?? ''}
    >
      {sortingIcon}
    </OrderButton>
  )
}

export const HeaderCell = ({
  colSpan,
  column,
  table,
  isSticky = false,
  getSize,
  getContext,
  getResizeHandler,
}: TableHeaderCellProps) => {
  const isExportPdf = useExportPdf()
  return (
    <TableHeaderCell
      colSpan={colSpan}
      style={{ width: getSize() }}
      title={column.columnDef.header || ''}
      isSticky={isSticky}
    >
      <span>{flexRender(column.columnDef.header, getContext())}</span>
      {!isExportPdf && <SortOrderButton column={column} />}
      {!isExportPdf && (
        <ColResizer
          onDoubleClick={() => column.resetSize()}
          onMouseDown={getResizeHandler()}
          onTouchStart={getResizeHandler()}
          className={`${table.options.columnResizeDirection} ${column.getIsResizing() ? 'isResizing' : ''}`}
        />
      )}
    </TableHeaderCell>
  )
}

export const renderEmptyState = columns => (
  <TableBodyRow>
    <TableBodyCellData
      style={{ width: '100%' }}
      colSpan={columns.length}
      className="text-center"
    >
      <IntlMessage id="dashboards.no-metrics-selected-for-table" />
    </TableBodyCellData>
  </TableBodyRow>
)

export const renderErrorState = (errors: string[], columnsLength: number) =>
  errors.map((error, index) => (
    <TableBodyRowError key={index}>
      <TableBodyCellError colSpan={columnsLength}>{error}</TableBodyCellError>
    </TableBodyRowError>
  ))

//fake empty column for virtualization scroll padding
export const renderVirtualPadding = (
  direction: number | undefined,
  Component: StyledComponent<'th' | 'td', any>
) => (direction ? <Component style={{ width: direction }} /> : null)
