import { Dispatch, SetStateAction, createContext, useContext } from 'react'

import { TableReporting, ColumnReporting, ClientData } from '../types'

type ContextData = {
  filters: string
  setFilters: (filters: string) => void
  tables: null | Array<TableReporting>
  id: null | string
  isFetching: boolean
  refetchTables: () => Promise<void>
  columnsView: null | ColumnReporting[]
  upsertColumnsOnTable?: ({
    refresh,
    columns,
  }: {
    refresh?: boolean
    columns: Array<ColumnReporting>
  }) => void
  removeColumnsOnTable: ({
    columns,
    refresh,
  }: {
    columns: Array<ColumnReporting>
    refresh: boolean
  }) => void

  createNewClient?: (payload: ClientData) => void
  removeClient?: (id: string) => void
  duplicateClient?: (id: string) => void
  clients: ClientData[]
  setId?: Dispatch<SetStateAction<string | null>>
  type: 'campaign' | 'client'
  updateCustomFieldData: ({
    columnId,
    rowId,
    payload,
  }: {
    columnId: string
    rowId: string
    payload: string
  }) => void
  views: any
  appliedView: any
  setAppliedView: any
  createNewView: any
  updateView: any
  editView: any
  handleDeleteView: any
  setView: any
  setColumnsView: any
  tableKey: any
  refreshTableKey: any
  invalidateViewDate: any
  setInvalidateViewDate: any
  accounts: any
  sources: any
  handleDragEnd: any
  lastRefreshTime: any
  currentTable: null | TableReporting
  loadingCurrentTable: boolean
  refetchCurrentTable: () => void
  changedColumns: number
  targetEditionColumn: ColumnReporting | null
  setTargetEditionColumn: any
  openOverviewModal:
    | 'metric'
    | 'custom'
    | 'deleteView'
    | 'filter'
    | 'multi-custom'
    | 'edit-client'
    | 'remove-client'
    | 'delete-table'
    | 'edit-table'
    | 'format-table'
    | null
  setOpenOverviewModal: any
  targetClient: string | null
  setTargetClient: (id: string) => void
  onSaveTableSuccess: (data: TableReporting) => void
}

const defaultContextData: ContextData = {
  filters: '[]',
  setFilters: () => {
    return
  },
  id: null,
  tables: null,
  isFetching: false,
  refetchTables: async () => {
    return
  },
  columnsView: [],
  clients: [],
  changedColumns: 1,
  updateCustomFieldData: function (_params: {
    columnId: string
    rowId: string
    payload: string
  }): void {
    throw new Error('Function not implemented.')
  },
  views: undefined,
  appliedView: undefined,
  setAppliedView: undefined,
  createNewView: undefined,
  updateView: undefined,
  editView: undefined,
  handleDeleteView: () => {},
  setView: undefined,
  setColumnsView: undefined,
  tableKey: undefined,
  refreshTableKey: undefined,
  invalidateViewDate: undefined,
  setInvalidateViewDate: undefined,
  accounts: undefined,
  sources: undefined,
  handleDragEnd: undefined,
  lastRefreshTime: undefined,
  currentTable: null,
  loadingCurrentTable: false,
  refetchCurrentTable: () => {},
  removeColumnsOnTable: () => {},
  setTargetEditionColumn: () => {},
  onSaveTableSuccess: () => {},
  targetEditionColumn: null,
  openOverviewModal: null,
  setOpenOverviewModal: () => {},
  type: 'campaign',
  targetClient: '',
  setTargetClient: () => {},
}

export const Context = createContext<ContextData>(defaultContextData)

export const useTableContext = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error(
      'useTableContext must be used within a TableContextProvider'
    )
  }
  return context
}
