import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable, width = '13', height = '3' }) => (
  <svg width={width} height={height} viewBox="0 0 13 3" fill="none">
    <path
      d="M3 1.50001C3 2.32844 2.32843 3.00001 1.5 3.00001C0.671573 3.00001 0 2.32844 0 1.50001C0 0.671583 0.671573 1.00583e-05 1.5 1.00583e-05C2.32843 1.00583e-05 3 0.671583 3 1.50001Z"
      fill={IconsColors[variable]}
    />
    <path
      d="M8 1.50001C8 2.32844 7.32843 3.00001 6.5 3.00001C5.67157 3.00001 5 2.32844 5 1.50001C5 0.671583 5.67157 1.00583e-05 6.5 1.00583e-05C7.32843 1.00583e-05 8 0.671583 8 1.50001Z"
      fill={IconsColors[variable]}
    />
    <path
      d="M13 1.5C13 2.32843 12.3284 3 11.5 3C10.6716 3 10 2.32843 10 1.5C10 0.671573 10.6716 0 11.5 0C12.3284 0 13 0.671573 13 1.5Z"
      fill={IconsColors[variable]}
    />
  </svg>
)

export default SvgComponent
