import styled from 'styled-components'

export const SectionSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
  padding-bottom: 10px;
  &.overview {
    max-width: 30%;
  }
`
export const AddSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 17px;
`

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

interface SectionOptionProps {
  active?: boolean
}
export const SectionOption = styled.div<SectionOptionProps>`
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  padding: 17px 17px 17px 17px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  max-width: 150px;
  min-width: 120px;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 15%;
    height: 70%;
    width: 2px;
    background-color: #d9d9d9;
  }

  /* Conditional bottom border when active */
  ${({ active }) =>
    active &&
    `
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15%; 
      width: 70%; 
      height: 5px; 
      background-color: #646EEB; 
    }
  `}
`

export const IconWrapper = styled.div`
  display: flex;
  margin-left: 15px;
  transform: rotate(90deg);
`
export const OptionText = styled.p`
  margin: 0px;
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
