import React, { useCallback, useEffect, useState } from 'react'
import useGetPowertableData from '@mm/api/src/powertables/requests/useGetPowertableData'
import { useUpdatePowerTableData } from '@mm/api/src/powertables/requests/useUpdatePowerTableData'
import { useIsMounted } from '@mm/utils'
import { calculateDateRange } from '@mm/ui/src/helpers/widgetDate'
import { Powertable as PowertableComponent } from './Powertable'
import { PowertableProps } from './types'

const Powertable = ({
  id,
  loading,
  shareable,
  dateChosen,
  pdf,
  dashboard,
  editLayout,
  onDelete,
  onEdit,
  onDuplicate,
}: PowertableProps) => {
  const isPublic = !!shareable || !!pdf
  const {
    data: response,
    isFetching,
    error,
    refetch,
  } = useGetPowertableData(id, shareable, pdf, dashboard)
  const { mutate: updatePowerTableSettings } = useUpdatePowerTableData()
  const isMounted = useIsMounted()
  const [dateLabel, setDateLabel] = useState('')

  useEffect(() => {
    if (isMounted()) refetch()
  }, [refetch, isMounted])

  const handleUpdatePowerTableSettings = useCallback(
    tableConfigurations => {
      const payload = {
        id,
        updateData: {
          breakdowns: [...response.breakdowns],
          metrics: [...response.metrics],
          configurations: {
            ...response.configurations,
            ...tableConfigurations,
          },
        },
      }
      updatePowerTableSettings(payload)
    },
    [id, response, updatePowerTableSettings]
  )

  useEffect(() => {
    if (response) {
      setDateLabel(calculateDateRange(dateChosen, response))
    }
  }, [dateChosen, response])

  return (
    <PowertableComponent
      dateLabel={dateLabel}
      widgetId={id}
      isLoading={isFetching}
      loading={loading}
      isPublic={isPublic}
      fetchError={error}
      dateChosen={dateChosen}
      response={response}
      editLayout={editLayout}
      updatePowerTableSettings={handleUpdatePowerTableSettings}
      onEdit={onEdit}
      onDuplicate={onDuplicate}
      onDelete={onDelete}
    />
  )
}

export { Powertable }
