import React, { createContext, useContext, useReducer, ReactNode } from 'react'
import { TableReportingClient, ColumnReporting } from '../types'
import { ColumnsActions } from './hooks/useColumns'
import { OpenMenuActions } from './hooks/useOpenMenu'

// Definición de tipos
interface TablesState {
  openMenu: string
  columns: ColumnReporting[]
  activeColumns: ColumnReporting[]
  clients: TableReportingClient[]
}

type TablesAction = OpenMenuActions | ColumnsActions

type TablesDispatch = (action: TablesAction) => void

interface TablesContextType {
  state: TablesState
  dispatch: TablesDispatch
}

// Estado inicial
const initialState: TablesState = {
  openMenu: '',
  columns: [],
  activeColumns: [],
  clients: [],
}

// Reducer para gestionar las acciones
function tablesReducer(state: TablesState, action: TablesAction): TablesState {
  switch (action.type) {
    case 'SET_OPEN_MENU':
      return { ...state, openMenu: action.payload }
    case 'SET_COLUMNS': {
      const columns = action.payload.sort(
        (c1, c2) => Number(c1.order) - Number(c2.order)
      )
      return {
        ...state,
        columns: columns,
        activeColumns: orderActiveColumns(columns.filter(c => c.active)),
      }
    }
    default:
      return state
  }
}

function orderActiveColumns(columns: ColumnReporting[]) {
  // Primero, filtra las columns con data.fixedOrder en true
  const columnsFixed = columns.filter(
    columna => typeof columna.data.fixedOrder === 'number'
  )
  // Luego, ordena esas columns por la propiedad 'order'
  columnsFixed.sort(
    (a, b) => (a.data.fixedOrder ?? 0) - (b.data.fixedOrder ?? 0)
  )

  // Ahora, filtra las columns con data.fixedOrder en false
  const columnsNoFixed = columns.filter(
    columna => !(typeof columna.data.fixedOrder === 'number')
  )

  // Finalmente, concatena las columns ordenadas
  const columnsOrdenadas = columnsFixed.concat(columnsNoFixed)

  return columnsOrdenadas
}

// Crear el contexto
const TablesContext = createContext<TablesContextType | undefined>(undefined)

// Proveedor del contexto
interface TablesProviderProps {
  children: ReactNode
}

const TablesProvider: React.FC<TablesProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(tablesReducer, initialState)

  const value = { state, dispatch }

  return (
    <TablesContext.Provider value={value}>{children}</TablesContext.Provider>
  )
}

// Hook personalizado para acceder al contexto
function useTablesContext(): TablesContextType {
  const context = useContext(TablesContext)
  if (context === undefined) {
    throw new Error(
      'useTablesContext debe ser utilizado dentro del TablesProvider'
    )
  }
  return context
}

// FIN CUSTOM HOOKS

export { TablesProvider, useTablesContext }
