import { useMutation } from '@tanstack/react-query'
import simpletableApi from '../index'

export const UPDATE_SIMPLETABLE_DATA = 'Simpletables_UpdateTableData'

const updateSimpleTableData = async (payload: { [key: string]: any }) => {
  const { id, ...data } = payload
  return simpletableApi.put(`/${id}`, data).then(response => response.data)
}

const useUpdateSimpletableData = () => {
  return useMutation(updateSimpleTableData, {
    mutationKey: [UPDATE_SIMPLETABLE_DATA],
  })
}

export { useUpdateSimpletableData }
