import React, { useEffect, useMemo, useState } from 'react'
import { WidgetContainer, MessageText, ReconnectButton } from './style'
import { ConnectionIssueIcon } from './ConnectionIssueSVG'
import { NoDataInRangeIcon } from './NoDataSVG'
import { AccountPermissionErrorSVG } from './AccountPermissionErrorSVG'
import { additionHandlers } from '@mm/api/src/tokenError.middleware'
import useResizeObserver from 'use-resize-observer'
import { GenericSVGError } from './GenericSVGError'
import { ReconnectIconSvg } from './ReconnectIconSVG'
import { IntlMessages } from '@mm/utils'
import WarningIconTooltip from '../CarouselItem/WarningIconTooltip'
import { EditIconSVG } from './EditIconSVG'
import { useIntl } from 'react-intl'

interface ServerError {
  message: string
  status?: string
  code?: string
  errorData?: ErrorData
  source?: string
}

interface ErrorData {
  source: string
  sourceName: string
  adAccountId: string
  intlMessageId?: string
  showDatasourceButton?: boolean
  adAccount: {
    id: string
    name: string
    currency: string
  }
}

const miniSize = 100
const smallSize = 140
const mediumSize = 450
const sizeWithoutImage = ['mini', 'small']
const noDataMessages = [
  'No data was found in the given time range',
  'No data was found for the configured alert in the indicated periods',
  'No data was found for the given time range',
]
const getErrorMessage = (error: ServerError | string | any) => {
  if (typeof error === 'string') return error
  return error?.message
}

interface NoResultWidgetProps {
  error: ServerError
  containerStyle?: React.CSSProperties
  widgetId: string
  isPublic: boolean
  onEdit?: (id: string) => void
}

const WidgetErrorMessage: React.FC<NoResultWidgetProps> = ({
  error,
  containerStyle,
  widgetId,
  isPublic,
  onEdit,
}) => {
  const { ref, height, width } = useResizeObserver<HTMLDivElement>()
  const intl = useIntl()

  const processError = useMemo(() => {
    return (error as any)?.response?.data ?? error
  }, [error])

  const [buttonSize, setButtonSize] = useState<
    'mini' | 'small' | 'medium' | 'large'
  >('small')

  const getButtonSize = () => {
    if (!width || !height) return
    if (width < miniSize || height < miniSize) {
      setButtonSize('mini')
    } else if (width < smallSize || height < smallSize) {
      setButtonSize('small')
    } else if (width < mediumSize || height < mediumSize) {
      setButtonSize('medium')
    } else {
      setButtonSize('large')
    }
  }

  useEffect(() => {
    getButtonSize()
  }, [height, width])
  const errorMsg = useMemo(
    () => getErrorMessage(processError),
    [processError, getErrorMessage]
  )

  if (processError?.code === 'ACCESS_TOKEN_HAS_EXPIRED') {
    const reconnect =
      additionHandlers[
        (processError?.errorData?.source ?? 'DATASOURCE').toUpperCase()
      ]
    return (
      <WidgetContainer style={containerStyle ?? {}} ref={ref}>
        {!sizeWithoutImage.includes(buttonSize) ? (
          <ConnectionIssueIcon />
        ) : null}
        <MessageText size={buttonSize}>
          <IntlMessages
            id="token-revalidate-error.message"
            values={{
              source: processError?.errorData?.sourceName,
            }}
          />
        </MessageText>

        {isPublic ? null : (
          <ReconnectButton onClick={reconnect} size={buttonSize}>
            <ReconnectIconSvg></ReconnectIconSvg>
            <IntlMessages id="token-revalidate-button.message" />
          </ReconnectButton>
        )}
      </WidgetContainer>
    )
  }

  if (processError?.code === 'MISSING_PERMISSION') {
    const reconnect = additionHandlers['DATASOURCE'.toUpperCase()]
    return (
      <WidgetContainer style={containerStyle ?? {}} ref={ref}>
        {!sizeWithoutImage.includes(buttonSize) ? (
          <AccountPermissionErrorSVG />
        ) : null}
        <MessageText size={buttonSize}>
          <IntlMessages
            id="token-revalidate-missing-permissions.message"
            values={{
              account: processError?.errorData?.adAccount?.name,
              source: processError?.errorData?.sourceName,
            }}
          />
        </MessageText>

        {isPublic ? null : (
          <ReconnectButton
            size={buttonSize}
            onClick={() =>
              reconnect({ source: processError?.errorData?.source })
            }
          >
            <ReconnectIconSvg></ReconnectIconSvg>
            <IntlMessages id="token-revalidate-missing-permissions-button.message" />
          </ReconnectButton>
        )}
      </WidgetContainer>
    )
  }

  if (noDataMessages.includes(errorMsg)) {
    return (
      <WidgetContainer style={containerStyle ?? {}} ref={ref}>
        {!sizeWithoutImage.includes(buttonSize) ? <NoDataInRangeIcon /> : null}

        <MessageText size={buttonSize}>
          <IntlMessages id="widget-no-data-was-found.message" />
        </MessageText>

        {onEdit && !isPublic ? (
          <ReconnectButton size={buttonSize} onClick={() => onEdit(widgetId)}>
            <EditIconSVG />
            <IntlMessages id="widget-no-data-was-found-button.message" />
          </ReconnectButton>
        ) : null}
      </WidgetContainer>
    )
  }

  if (processError?.code === 'CUSTOM_ERROR_MESSAGE') {
    const message = processError?.errorData?.intlMessageId
      ? intl.formatMessage({ id: processError?.errorData?.intlMessageId })
      : (processError?.message ?? 'widget-default-error.message')
    const reconnect = additionHandlers['DATASOURCE'.toUpperCase()]

    return (
      <WidgetContainer style={containerStyle ?? {}} ref={ref}>
        {widgetId && processError?.message ? (
          <WarningIconTooltip
            error={processError?.message}
            elementId={widgetId}
          />
        ) : null}
        {!sizeWithoutImage.includes(buttonSize) ? <GenericSVGError /> : null}

        <MessageText size={buttonSize}>{message}</MessageText>

        {isPublic || !processError?.errorData?.showDatasourceButton ? null : (
          <ReconnectButton
            size={buttonSize}
            onClick={() =>
              reconnect({ source: processError?.errorData?.source })
            }
          >
            <ReconnectIconSvg></ReconnectIconSvg>
            <IntlMessages id="token-revalidate-missing-permissions-button.message" />
          </ReconnectButton>
        )}
      </WidgetContainer>
    )
  }

  return (
    <WidgetContainer style={containerStyle ?? {}} ref={ref}>
      {widgetId && processError?.message ? (
        <WarningIconTooltip
          error={processError?.message}
          elementId={widgetId}
        />
      ) : null}
      {!sizeWithoutImage.includes(buttonSize) ? <GenericSVGError /> : null}

      <MessageText size={buttonSize}>
        <IntlMessages id="widget-default-error.message" />
      </MessageText>
    </WidgetContainer>
  )
}

export default WidgetErrorMessage
