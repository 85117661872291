import format from 'format-number'
import { formatDuration } from '@mm/utils/src/helpers/Utils'
import {
  DefaultDecimalsByMetric,
  monetaryMetrics,
  numberMetrics,
  percentageMetrics,
  timeMetrics,
  timeMetrics_ms,
} from './constants'

export const getDecimals = (formatedType?: string, decimals?: number) => {
  if (decimals) return decimals
  if (formatedType && DefaultDecimalsByMetric[formatedType])
    return DefaultDecimalsByMetric[formatedType]

  if (
    formatedType?.startsWith('conversion_cost') ||
    formatedType?.startsWith('cost_offsite_conversion')
  ) {
    return 2
  }

  return undefined
}

export const formatMetric = (
  type: string,
  value: string | number,
  decimals?: number
) => {
  let formatedType = type?.replace('metrics.', '')
  if (type.includes('compare_percentage_')) {
    if (!isFinite(+value)) return '∞'
    const percentageFormat = format({
      suffix: '%',
      round: decimals ?? 2,
      padRight: decimals ?? 2,
    })
    return percentageFormat(+value)
  }
  formatedType = formatedType
    .replace('compare_value_', '')
    .replace('compare_absolute_', '')
  const defaultDecimals = getDecimals(formatedType, decimals)
  if (
    (type.includes('value') ||
      type.includes('cost_') ||
      type.includes('_cost') ||
      type.endsWith('_total') ||
      type.endsWith('_subtotal') ||
      type.endsWith('_price') ||
      type.endsWith('_ticket') ||
      monetaryMetrics.includes(formatedType)) &&
    !numberMetrics.includes(formatedType)
  ) {
    const currencyFormat = format({
      prefix: '$',
      round: defaultDecimals ?? 0,
      padRight: defaultDecimals ?? 0,
    })
    return currencyFormat(+value)
  }
  if (
    percentageMetrics.includes(formatedType) ||
    formatedType.toLowerCase().includes('rate')
  ) {
    const percentageFormat = format({
      suffix: '%',
      round: decimals ?? 2,
      padRight: decimals ?? 2,
    })
    return percentageFormat(+value)
  }
  if (timeMetrics.includes(formatedType)) {
    return formatDuration(value)
  }

  if (timeMetrics_ms.includes(formatedType)) {
    return formatDuration(Number(value) / 1000)
  }

  const commonFormat = format({
    round: defaultDecimals,
    padRight: defaultDecimals,
  })
  return commonFormat(+value)
}

export const formatString = (string: string) =>
  string
    .replaceAll('_', ' ')
    .toLowerCase()
    .replace(/^\w/, c => c.toUpperCase())

export const flattenPowerTableRow = (data: any[], parentValues: any[] = []) => {
  return data.reduce((acc, item) => {
    const currentValues = [...parentValues, item.value]

    if (item.subRow && item.subRow.length > 0) {
      acc.push(...flattenPowerTableRow(item.subRow, currentValues))
    } else {
      acc.push([...currentValues, ...item.rowValues])
    }

    return acc
  }, [])
}
