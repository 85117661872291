import React from 'react'
import IconComponent, {
  IconVariable,
} from '@mm/ui/src/components/IconComponent/IconComponent'
import { DATA_SOURCES_ICONS } from './utils'
import { WidgetIconContainer } from './styles'

const WidgetIcon = ({ sources }: { sources: string[] }) => {
  return (
    <WidgetIconContainer>
      {sources.length > 0 &&
        sources.map((source: string, index) => (
          <IconComponent
            key={index}
            icon={DATA_SOURCES_ICONS[source] ?? ''}
            width="20"
            height="20"
            variable={IconVariable.Primary}
          />
        ))}
    </WidgetIconContainer>
  )
}

export default WidgetIcon

const WidgetIconV2 = ({ sources }: { sources: string[] }) => {
  return (
    sources.length > 0 &&
    sources.map((source: string, index) => (
      <IconComponent
        key={index}
        icon={DATA_SOURCES_ICONS[source] ?? ''}
        width="20"
        height="20"
        variable={IconVariable.Primary}
      />
    ))
  )
}

export { WidgetIconV2 }
