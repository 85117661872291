import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({
  variable,
  width = '16',
  height = '16',
}: {
  variable: keyof typeof IconsColors
  width?: string
  height?: string
}) => {
  return (
    <svg
      viewBox="-0.5 -0.5 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="Sort-Horizontal--Streamline-Solar-Ar.svg"
      height={width}
      width={height}
    >
      <path
        d="M11.25 5 3.75 5m0 0 2.578125 -2.5M3.75 5l2.578125 2.5"
        stroke={IconsColors[variable]}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="m3.75 10 7.5 0m0 0 -2.578125 -2.5M11.25 10l-2.578125 2.5"
        stroke={IconsColors[variable]}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  )
}
export default SvgComponent
