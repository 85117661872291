import React, { Component } from 'react'
import Select from 'react-select'
import { Tooltip } from 'reactstrap'
import HelpIcon from '../../Icons/HelpIcon'

const colourStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: '14',
      color: isDisabled ? '#aaa !important' : '#1B215C !important',
      '&:hover': {
        // Overwrittes the different states of border
        backgroundColor: data.color
          ? `${data.color} !important`
          : '#EDEFFE !important',
        color: '#1B215C !important',
        cursor: 'pointer',
      },
      backgroundColor: data.color
        ? `${data.color} !important`
        : isDisabled
          ? 'null'
          : isSelected
            ? '#EDEFFE !important'
            : isFocused
              ? '#EDEFFE !important'
              : null,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ...(data.color
        ? {
            minHeight: '35px',
            width: '95%',
            margin: '2px auto',
            opacity: 0.8,
          }
        : {}),

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'red' : '#4552e8'),
      },
    }
  },
  menuList: base => ({
    ...base,

    '::-webkit-scrollbar': {
      width: '7px',
      height: '7px',
    },
    '::-webkit-scrollbar-thumb': {
      background: ' #717bea',
      borderRadius: '40px',
    },
  }),
}

class SmartReportingSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showErrors: false,
      showHelpMessage: false,
    }

    this.selectRef = React.createRef(null)
  }

  handleSelectChange = option => {
    let selection
    let isAllSelected
    if (this.props.isMulti) {
      isAllSelected = option.find(opt => opt.value === 'all')
      if (isAllSelected) {
        selection = this.props.options.reduce((selected, opt) => {
          if (opt.value !== 'all') {
            selected.push(opt)
          }
          return selected
        }, [])
      }
    }
    this.props.onChange({
      target: {
        name: this.props.name,
        ...(isAllSelected ? selection : option),
      },
    })
  }

  handleClick = () => {
    if (this.props.disabled && !this.props.resized) {
      setTimeout(() => {
        this.setState({ showErrors: false })
      }, 5000)
      this.setState({ showErrors: true })
    }
  }

  defaultLoadingMessage = () => 'Loading...'

  componentDidUpdate(prevProps) {
    if (
      !!prevProps.value &&
      (this.props.value === '' || this.props.value === null)
    ) {
      this.selectRef.current.clearValue()
    }
  }

  render() {
    const {
      name,
      value,
      label,
      options,
      error,
      placeholder,
      defaultValue,
      isMulti,
      disabled,
      errorMessage,
      isLoading,
      loadingMessage,
      onBlur,
      onKeyDown,
      autoFocus,
      isClearable,
      menuPlacement,
      maxMenuHeight,
      helpTooltip,
    } = this.props

    const { showHelpMessage } = this.state

    const valueCalc =
      options && ![undefined, null].includes(value)
        ? isMulti
          ? options.filter(option => value.includes(option.value))
          : options.find(option => option.value === value)
        : null

    const parsedOptions = (options || []).map(opt => {
      if (opt.disabled && opt.disabledReason) {
        return {
          value: opt.value,
          label: <span title={opt.disabledReason}>{opt.label}</span>,
          disabled: opt.disabled,
        }
      }
      return opt
    })
    return (
      <div
        className="forms-inputs"
        style={{ cursor: disabled ? 'not-allowed' : 'default' }}
        onClick={this.handleClick}
      >
        {label && (
          <div style={{ display: 'flex' }}>
            <label>{this.props.label}</label>
            {helpTooltip && (
              <div style={{ marginLeft: '10px' }}>
                <Tooltip
                  placement={'bottom'}
                  isOpen={showHelpMessage}
                  style={{ zIndex: 1000 }}
                  target={`select-${name}`}
                  toggle={() =>
                    this.setState({
                      showHelpMessage: !showHelpMessage,
                    })
                  }
                >
                  {helpTooltip}
                </Tooltip>
                <HelpIcon id={`select-${name}`} />
              </div>
            )}
          </div>
        )}
        <Select
          ref={this.selectRef}
          isMulti={isMulti}
          classNamePrefix="react-select"
          name={name}
          value={valueCalc}
          onChange={this.handleSelectChange}
          options={parsedOptions}
          styles={colourStyles}
          label={label}
          isSearchable={true}
          placeholder={placeholder}
          maxMenuHeight={maxMenuHeight}
          defaultValue={defaultValue}
          isDisabled={disabled}
          className={this.state.showErrors ? 'clicked-disabled' : ''}
          isOptionDisabled={option => !!option.disabled}
          isLoading={isLoading}
          loadingMessage={loadingMessage || this.defaultLoadingMessage}
          menuShouldScrollIntoView={false}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
          isClearable={isClearable}
          menuPlacement={menuPlacement}
        />
        {(errorMessage || error) && (
          <div
            className="error-message"
            style={{ opacity: this.state.showErrors || error ? '1' : '0' }}
          >
            <span>{errorMessage || error}</span>
          </div>
        )}
      </div>
    )
  }
}

export default SmartReportingSelect
