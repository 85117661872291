import styled from 'styled-components'

export const WidgetIconContainer = styled('div')`
  position: absolute;
  top: 10px;
  left: 15px;
  display: flex;
  flex-direction: row;
  z-index: 998;
`
