import React from 'react'
import { useIntl } from 'react-intl'
import Loader from '@mm/ui/src/components/Loader'
import { useExportPdf } from '@mm/ui/src/components/GridLayout/ExportPdfProvider'
import { WidgetActionMenu } from '@mm/ui/src/components/WidgetActionMenu'
import { WidgetIconV2 } from '@mm/ui/src/components/WidgetIcon'
import WidgetErrorMessage from '@mm/ui/src/components/WidgetErrorMessage/WidgetErrorMessage'
import {
  DateRangeSelected,
  ErrorMessage,
  WarningIconTooltipContainer,
  WidgetHeader,
  WidgetTitle,
  WidgetWrapperStyled,
  Wrapper,
} from './styles'
import { WidgetWrapperProps } from './types'
import WarningIconTooltip from '../CarouselItem/WarningIconTooltip'

//@ts-ignore
const showActions = import.meta.env.VITE_APP_IS_PUBLIC === 'false'

const ERROR_LOADING_TABLE = {
  id: 'dashboards.simpletable-error-loading-table',
}
const SERVER_ERROR = { id: 'dashboards.simpletable-server-error' }

const WidgetWrapper = ({
  widgetId,
  componentId,
  title,
  dateLabel,
  showIcons = false,
  iconsSource = [],
  onDelete,
  onEdit,
  onDuplicate,
  children,
  isLoading,
  fetchError,
  errorsData,
  isPublic,
  dateChosen,
  parsedError,
}: WidgetWrapperProps) => {
  const { formatMessage } = useIntl()
  const isExportPdf = useExportPdf()

  return (
    <WidgetWrapperStyled id={`${componentId}-${widgetId}`}>
      <Wrapper>
        {isLoading ? (
          <Loader style={{ margin: 'auto' }} />
        ) : (
          <>
            {fetchError && (
              <ErrorMessage>
                {`${formatMessage(ERROR_LOADING_TABLE)}: ${fetchError.response?.data?.message || formatMessage(SERVER_ERROR)}`}
              </ErrorMessage>
            )}
            <WidgetHeader isExportPdf={isExportPdf}>
              {parsedError && (
                <WarningIconTooltipContainer>
                  <WarningIconTooltip
                    error={parsedError}
                    elementId={widgetId}
                  />
                </WarningIconTooltipContainer>
              )}
              {showIcons && <WidgetIconV2 sources={iconsSource} />}
              {!errorsData && (
                <>
                  <WidgetTitle>{title}</WidgetTitle>
                  {dateLabel && (
                    <DateRangeSelected>{dateLabel}</DateRangeSelected>
                  )}
                </>
              )}
              {showActions && (
                <WidgetActionMenu
                  isLoading={isLoading}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onDuplicate={onDuplicate}
                />
              )}
            </WidgetHeader>
            {errorsData ? (
              <WidgetErrorMessage
                error={errorsData}
                containerStyle={{ height: 'calc(100% - 48px)' }}
                widgetId={widgetId}
                isPublic={isPublic}
                onEdit={dateChosen?.dateFilterRange ? undefined : onEdit}
              />
            ) : (
              children
            )}
          </>
        )}
      </Wrapper>
    </WidgetWrapperStyled>
  )
}

export { WidgetWrapper }
