import { useMutation } from '@tanstack/react-query'
import powerTableApi from '../index'

export const UPDATE_POWERTABLE_DATA = 'Powertables_UpdatePowertableData'

const updatePowerTableData = async (payload: { [key: string]: any }) => {
  const { id, updateData } = payload
  const { ...data } = updateData
  return powerTableApi.put(`/${id}`, data).then(response => response.data)
}

const useUpdatePowerTableData = () => {
  return useMutation(updatePowerTableData, {
    mutationKey: [UPDATE_POWERTABLE_DATA],
  })
}

export { useUpdatePowerTableData }
