import { Table as TableReactstrap } from 'reactstrap'
import styled from 'styled-components'
import { TableHeaderCellProp } from './types'

export const TableWrapper = styled.div<{ isExportPdf?: boolean }>`
  width: max-content;
  max-width: 100%;
  height: auto;
  overflow: auto;
  position: relative;
  left: 0;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    padding: 2rem 0;
    @media (min-width: 768px) {
      width: 5px;
      height: 5px;
    }
  }

  ${({ isExportPdf }) =>
    isExportPdf &&
    ` &::-webkit-scrollbar {
        display: none;
      }`}

  &::-webkit-scrollbar-track {
    margin-top: 1px;
    padding: 10px 0;
    border-radius: 40px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #717bea;
    border-radius: 40px;
  }

  .table th {
    border: 1px solid #d9d9d9 !important;
    border-bottom: none !important;
    border-right: none !important;
    border-right: 1px solid #d9d9d9 !important;
  }

  .table td {
    border: 1px solid #d9d9d9 !important;
    border-bottom: none !important;
    border-right: none !important;
    vertical-align: middle !important;

    &:last-child {
      border-right: 1px solid #d9d9d9 !important;
    }
  }
`

export const Table = styled(TableReactstrap)`
  width: 100%;
  display: grid;
  margin-right: 1rem;
  background-color: #fff;
  position: relative;
  left: 0;
`

export const TableHead = styled.thead`
  display: grid;
  position: relative;
  border-bottom: 1px solid #d9d9d9;

  @media (min-width: 992px) {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`

export const FakeEmptyTh = styled.th`
  display: flex;
`

export const FakeEmptyTd = styled.td`
  display: flex;
`

export const TableHeaderRow = styled.tr`
  display: flex;
  background-color: #fff;
`

export const ColResizer = styled.div`
  position: absolute;
  top: 0;
  width: 4px;
  height: 100%;
  background: #dedfeb;
  border-radius: 1rem;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  opacity: 0;

  &.isResizing {
    background: #717bea;
    opacity: 1;
    cursor: col-resize;
  }

  &.ltr {
    right: 0;
  }

  &.rtl {
    left: 0;
  }
`

export const TableHeaderCell = styled.th<TableHeaderCellProp>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: #fff;
  font-weight: 500;
  vertical-align: bottom;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  &:hover {
    ${ColResizer} {
      opacity: 1;
    }
  }

  @media (min-width: 992px) {
    ${({ isSticky }) =>
      isSticky &&
      `
        position: sticky;
        left: 0;
        z-index: 2;
      `}
  }
`

export const TableFirstHeaderCell = styled(TableHeaderCell)`
  position: relative;

  @media (min-width: 992px) {
    position: sticky;
    left: 0;
    z-index: 2;
  }
`

export const TableBody = styled.tbody`
  display: grid;
  position: relative;
  margin-bottom: 2rem;

  .tbody {
    background-color: #fff;

    &:hover {
      background-color: #fafafa !important;
      th {
        background-color: #fafafa !important;
      }
    }
  }
`

export const TableBodyRow = styled.tr`
  width: 100%;
  display: flex;
  position: absolute;

  &:last-child {
    border-bottom: 1px solid #d9d9d9;
  }

  &:hover {
    background-color: #fafafa !important;
  }
`

export const TableBodyCellError = styled.td`
  width: 100%;
  display: flex;
  padding: 0.75rem;
  white-space: pre;
  vertical-align: top;
`

export const TableBodyRowError = styled.tr`
  width: 100%;
  display: flex;
  position: relative;
  color: #c40000;
`

export const TableBodyHeaderCell = styled.th`
  display: flex;
  position: relative;
  padding: 0.75rem;
  background-color: #fff;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;

  @media (min-width: 992px) {
    position: sticky;
    left: 0;
    z-index: 1;
  }
`

export const OrderButton = styled.button`
  padding: 0 0 0 4px;
  background: none;
  border: none;
  color: #c9c9c9;

  &:hover,
  &.asc,
  &.desc {
    color: rgba(0, 0, 0, 0.6);
  }
`

export const TableBodyCellData = styled(TableHeaderCell)`
  border: none !important;
`

export const TableBodyCell = styled.td<{
  hoverMessage: string
  warning: string
}>`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  color: ${({ hoverMessage, warning }) =>
    hoverMessage ? (warning ? '#ff9800' : 'red') : 'inherit'};
  white-space: pre;
  vertical-align: middle;
`
