import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({
  variable,
  width = '14',
  height = '14',
}: {
  variable: keyof typeof IconsColors
  width?: string
  height?: string
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 10"
      stroke={IconsColors[variable]}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5H12M1 1H14M6 9H9"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default SvgComponent
