import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({
  variable,
  width = '13',
  height = '13',
}: {
  variable: keyof typeof IconsColors
  width?: string
  height?: string
}) => {
  return (
    <svg
      width={width}
      height={height}
      stroke={IconsColors[variable]}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.72248 5.27752L12 1.00002M12 1.00002L8.85715 1M12 1.00002L11.9999 4.14272M5.27803 7.72222L1.00027 12M1.00027 12L4.1431 12M1.00027 12L1.00031 8.85728M7.72248 7.72222L12 11.9997M12 11.9997L12 8.85697M12 11.9997L8.85721 11.9997M5.27803 5.27803L1.00002 1.00002M1.00002 1.00002L1 4.14277M1.00002 1.00002L4.14279 1.00006"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default SvgComponent
