import { useQuery } from '@tanstack/react-query'

import tablesApi from '../'
import { DateChosen } from '@mm/ui/src/components/Forms/WidgetForms/widget'

export const FETCH_TABLE_DATA = 'Fetch_Tables_Data'

export const fetchTableData = async (params: {
  id: string
  viewId?: string
  dateChosen?: DateChosen
  shareable?: string
  pdf?: string
  dashboard?: any
  refresh?: boolean
}) => {
  const { id, viewId, dateChosen, shareable, pdf, dashboard, refresh } = params
  const viewParam = viewId ? `&viewId=${viewId}` : ''
  const urlSearchParams = new URLSearchParams()
  let dateChosenParams = ''
  if (dateChosen) {
    Object.entries(dateChosen).map(([key, value]) => {
      value && urlSearchParams.append(key, String(value))
    })
    dateChosenParams = dateChosen ? `&${urlSearchParams}` : ''
  }

  if (shareable || pdf) {
    const param = shareable ? `shareable=${shareable}` : `pdf=${pdf}`

    return (
      await tablesApi.get(
        `/shareable/${id}/data?${viewParam}${dateChosenParams}&${param}&dashboard=${dashboard.id}${refresh ? '&refresh=true' : ''}`
      )
    ).data
  }

  return (
    await tablesApi.get(
      `/${id}/data?${viewParam}${dateChosenParams}${refresh ? '&refresh=true' : ''}`
    )
  ).data
}

export function useTableData(
  params: {
    id: string
    viewId?: string
    dateChosen?: DateChosen
    shareable?: string
    pdf?: string
    dashboard?: any
  },
  queryOptions: any = {}
) {
  const { id, viewId, dateChosen, shareable, pdf, dashboard } = params
  const stringDateChosen = dateChosen ? JSON.stringify(dateChosen) : ''
  return useQuery(
    [FETCH_TABLE_DATA, id, viewId, stringDateChosen, shareable, pdf, dashboard],
    () => fetchTableData(params),
    {
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60,
      retry: false,
      ...queryOptions,
    }
  )
}

export default useTableData
